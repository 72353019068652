import React, { useState, useEffect } from "react";
import { Nav, NavDropdown } from "react-bootstrap";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Form, Modal } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";

const Sidebar = () => {
  return (
    <>
      <div
        className="sidebar"
        style={{ backgroundColor: "#cb1016bf", width: "100%" }}
      >
          <Divider />
          <ListItem className="sidebar-element" button key={""}>
            <ListItemIcon>
              <NavDropdown
                style={{ color: "white !important", fontSize: "13pt" }}
                title="Clientes"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/clients">Crear</NavDropdown.Item>
                <NavDropdown.Item href="/search/clients">
                  Consultar
                </NavDropdown.Item>
              </NavDropdown>
            </ListItemIcon>
          </ListItem>
          <Divider />
          <ListItem className="sidebar-element" button key={""}>
            <ListItemIcon>
              <NavDropdown
                style={{ fontSize: "13pt" }}
                title="Créditos"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/credits">Crear</NavDropdown.Item>
                <NavDropdown.Item href="/search/credits">
                  Consultar
                </NavDropdown.Item>
              </NavDropdown>
            </ListItemIcon>
          </ListItem>
          <Divider />
          <Nav.Link href="/users">
            <ListItem className="sidebar-element" button key={""}>
              <ListItemIcon>
                <li style={{ color: "white", fontSize: "13pt" }}>
                  Usuarios
                </li>
              </ListItemIcon>
            </ListItem>
          </Nav.Link>
          <Divider />
          <Nav.Link style={{ color: "white" }} href="/uvr">
            <ListItem className="sidebar-element" button key={""}>
              <ListItemIcon>
                <li style={{ color: "white", fontSize: "13pt" }}>
                  Valores UVR
                </li>
              </ListItemIcon>
            </ListItem>
          </Nav.Link>
          <Divider />
          <Nav.Link
            style={{ color: "white", textAlign: "left" }}
            href="/documents"
          >
            <ListItem className="sidebar-element" button key={""}>
              <ListItemIcon>
                <li style={{ color: "white", fontSize: "13pt" }}>
                  Informes
                </li>
              </ListItemIcon>
            </ListItem>
          </Nav.Link>
          <Divider />
          <Nav.Link style={{ color: "white" }} href="/closedPeriods">
            <ListItem className="sidebar-element" button key={""}>
              <ListItemIcon>
                <li style={{ color: "white", fontSize: "13pt" }}>
                  Cierre de periodo
                </li>
              </ListItemIcon>
            </ListItem>
          </Nav.Link>
          <Divider />
      </div>
    </>
  );
};

export default Sidebar;
