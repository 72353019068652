import React, { Component, useState } from 'react';
import axios from 'axios'
import $ from 'jquery'
import * as apiMethods from '../res/apiMethods.js';
import FileSaver from 'file-saver';
import readXlsxFile from 'read-excel-file'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from "../res/logoBase64";
import footer from "../res/footerBase64";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { forEach } from "react-bootstrap/cjs/ElementChildren";
import ReactExport from "react-export-excel";
import { CSVLink, CSVDownload } from "react-csv";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Swal from "sweetalert2";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import Sidebar from "../components/Sidebar";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Documents extends Component {

    toFixed(num, fixed) {
        if (num != null) {
            let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
            return num.toString().match(re)[0];
        } else {
            return 0
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            deudores: [],
            vlrCapital: '',
            plazo: '',
            estado: '',
            moneda: '',
            fechaAprobacion: new Date(),
            fechaDesembolso: new Date(),
            transacciones: [],
            vlrInicial: '',
            transaccionesData: [],
            clientesData: [],
            id: '',
            creditos: [],
            fechaPago: new Date(),
            montoPago: '',
            cntTransacciones: '',
            pagosUsuario: [],
            garantia: '',
            amortizacion: '',
            auxProxCorte: null,
            auxProxCorteSave: null,
            interes: '',
            interesMoratorio: '',
            seguroVida: '',
            seguroTodoRiesgo: '',
            fechaPagoReciente: null,
            creditos: [],
            fechaPagoProximo: null,
            vlrSegVida: '',
            vlrSegTodoRiesgo: '',
            vlrInteres: '',
            vlrInteresMora: '',
            saldoSegVida: '',
            saldoSegTodoRiesgo: '',
            liquidacionesData: [],
            liquidacionesResp: [],
            proyeccionData: [],
            proyeccion: [],
            estadoReal: '',
            pagosPorCreditos: [],
            detalleData: [],
            labelsDetails: ['Saldo Inicial', 'Movimientos', 'Aplicacion del pago', 'Nuevo saldo'],
            resPeriodo: [],
            iteradorPagos: [],
            pasoEdicionLocal: '',
            liquidacionesDataUVR: [],
            detalleDataUVR: [],
            auxTransacciones: [],
            showModal: false,
            showModalLiq: false,
            fechaRef: '',
            proyState: 0,
            auxFechaPagoReciente: '',
            auxFechaPago: '',
            lastUVR: '',
            emptyPayment: false,
            initialPDF: false,
            financialCode: '',
            showModalDeleteCredito: false,
            showModalDeletePayment: false,
            filename: '',
            file: '',
            cntCargasUVR: '',
            startDate: new Date(),
            showModalDownloadGlobal: false,
            endDate: new Date(),
            formatter: new Intl.NumberFormat('en-US', { currency: 'USD' }),
            clientsName: [],
            estado: '',
            payments: [],
            transacciones: [],
            informeGerencialData: [],
            massiveLiquidationDialog: false,
            periodicReportDialog: false,
            managementReportDownloadDialog: false,
            startDateSelected: false,
            liquidated: false,
            selectedCredit: '',
            reportType: '',
            excelCreated: false
        }
    }

    componentDidMount() {
        window.sessionStorage.setItem('first', true);
        document.title = "Informes"
        let actUsrToken = window.sessionStorage.getItem('localToken');
        let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
        let localId = decodedPayload.usrId;

        axios
            .get(apiMethods.GET_USUARIO_ID + localId, {
                headers: {
                    Authorization: actUsrToken
                }
            })
            .then(response => {
                var usr = response.data.users.username

                $(document).ready(function () {
                    $('#alertCli').hide();
                    $('#warnalert').hide();
                });
            })
            .catch(error => {
                console.error(error)
            })
    }

    liquidateCredits = (e) => {
        e.preventDefault()
        this.setState({ massiveLiquidationDialog: false })
        Swal.fire({ title: "Cargando", allowOutsideClick: false });
        Swal.showLoading();
        let actUsrToken = window.sessionStorage.getItem('localToken');
        axios
            .post(apiMethods.LIQUIDATE_ALL_CREDITS, { save: true }, {
                headers: {
                    Authorization: actUsrToken
                }
            })
            .then(response => {
                Swal.close();
                if (response.data.payments) {
                    this.setState({ payments: response.data.payments, liquidated: true, massiveLiquidationDialog: true });
                } else {
                    Swal.fire({
                        title: 'No hay créditos para liquidar',
                        icon: 'warning',
                        confirmButtonText: 'Aceptar'
                    })
                }
            })
    }

    handleToggle = (e, element, reportType) => {
        if (e.preventDefault) e.preventDefault()
        this.setState({ [element]: !this.state[element], reportType: reportType })
    }

    loadNames(id) {
        let actUsrToken = window.sessionStorage.getItem('localToken');
        axios
            .get(apiMethods.GET_CLIENTE_DOCUMENTO + id, {
                headers: {
                    Authorization: actUsrToken
                }
            })
            .then(response => {
                return response.data.client.nombre;
            })
    }

    startComponent = (e, search) => {
        e.preventDefault()
        let actUsrToken = window.sessionStorage.getItem('localToken');
        axios
            .get(apiMethods.GET_CREDITO_ID + search, {
                headers: {
                    Authorization: actUsrToken
                }
            })
            .then(response => {
                var auxAprobacion = new Date()
                var auxGiro = new Date()

                if (response.data.credits.fechaAprobacion == null) {
                    auxAprobacion = new Date()
                } else {
                    auxAprobacion = new Date(response.data.credits.fechaAprobacion)
                }
                if (response.data.credits.fechaDesembolso == null) {
                    auxGiro = null
                } else {
                    auxGiro = new Date(response.data.credits.fechaDesembolso)
                    auxGiro = new Date(auxGiro.getTime() + Math.abs(auxGiro.getTimezoneOffset() * 60000));
                }

                var vlrInicialAux = response.data.credits.vlrInicial
                const formatter = new Intl.NumberFormat('en-US', {
                    currency: 'USD',
                })
                vlrInicialAux = formatter.format(vlrInicialAux)
                var vlrCapitalAux = response.data.credits.vlrCapital
                var vlrInteresAux = response.data.credits.vlrInteres
                var vlrInteresMoraAux = response.data.credits.vlrInteresMora
                var vlrSegVidaAux = response.data.credits.saldoSegVida
                var vlrSegTodoRiesgoAux = response.data.credits.saldoSegTodoRiesgo

                vlrCapitalAux = formatter.format(vlrCapitalAux)
                vlrInteresAux = formatter.format(vlrInteresAux)
                vlrInteresMoraAux = formatter.format(vlrInteresMoraAux)
                vlrSegVidaAux = formatter.format(vlrSegVidaAux)
                vlrSegTodoRiesgoAux = formatter.format(vlrSegTodoRiesgoAux)

                if (response.data.credits.fechaInicial) {
                    var auxFechaInicial = response.data.credits.fechaInicial.replace(/-/g, '/')
                }

                this.setState({
                    deudores: response.data.credits.deudores,
                    vlrCapital: vlrCapitalAux,
                    plazo: response.data.credits.plazo,
                    moneda: response.data.credits.moneda,
                    estado: response.data.credits.estado,
                    financialCode: response.data.credits.financialCode,
                    fechaInicial: auxFechaInicial,
                    fechaAprobacion: auxAprobacion,
                    fechaDesembolso: auxGiro,
                    transaccionesData: response.data.credits.transacciones,
                    vlrInicial: vlrInicialAux,
                    id: response.data.credits.id,
                    garantia: response.data.credits.garantia,
                    amortizacion: response.data.credits.amortizacion,
                    interes: response.data.credits.interes,
                    auxProxCorteSave: response.data.credits.fechaProxCorte,
                    auxProxCorte: response.data.credits.fechaProxCorte,
                    interesMoratorio: response.data.credits.interesMoratorio,
                    vlrInteres: vlrInteresAux,
                    vlrInteresMora: vlrInteresMoraAux,
                    liquidacionesResp: response.data.credits.liquidaciones,
                    saldoSegVida: vlrSegVidaAux,
                    saldoSegTodoRiesgo: vlrSegTodoRiesgoAux,
                    seguroVida: response.data.credits.seguroVida,
                    seguroTodoRiesgo: response.data.credits.seguroTodoRiesgo,
                    vlrSegVida: response.data.credits.vlrSegVida,
                    vlrSegTodoRiesgo: response.data.credits.vlrSegTodoRiesgo,
                    proyeccionData: response.data.credits.proyeccion,
                    liquidacionesData: response.data.credits.liquidaciones,
                    liquidaciones: response.data.credits.liquidaciones,
                    estadoReal: response.data.credits.estado,
                    vlrCuota: response.data.credits.vlrCuota,
                    pasoEdicionLocal: response.data.credits.pasoEdicion,
                    vlrInicialUVR: response.data.credits.vlrInicialUVR,
                    auxTransacciones: response.data.credits.transacciones,
                    auxFechaPagoReciente: response.data.credits.fechaPagoReciente
                })

                for (let i = 0; i < this.state.deudores.length; i++) {
                    axios
                        .get(apiMethods.GET_CLIENTE_DOCUMENTO + this.state.deudores[i], {
                            headers: {
                                Authorization: actUsrToken
                            }
                        })
                        .then(response => {
                            var auxArray = this.state.clientesData
                            auxArray.push(response.data.client)
                            this.setState({ clientesData: auxArray })
                        })
                        .catch(error => {
                            console.error(error)
                        })
                }

                const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DEC"];

                var detallesData = [];
                var detallesDataUVR = [];

                var transaccionesData = this.state.transaccionesData
                for (let i = 0; i < transaccionesData.length; i++) {
                    var local = transaccionesData[i]
                    const formatter = new Intl.NumberFormat('en-US', {
                        currency: 'USD',
                    })
                    let current_datetime = new Date(local.fechaTx)
                    let formatted_date = current_datetime.getDate() + "-" + months[current_datetime.getMonth()] + "-" + current_datetime.getFullYear()
                    var localData = {
                        siCapital: 0,
                        siInteresCorriente: 0,
                        siInteresMoratorio: 0,
                        siSegVida: 0,
                        siSegTodoRiesgo: 0,
                        moInteresCorriente: 0,
                        moInteresMoratorio: 0,
                        moSegVida: 0,
                        moSegTodoRiesgo: 0,
                        apCapital: 0,
                        apInteresCorriente: 0,
                        apInteresMoratorio: 0,
                        apSegVida: 0,
                        apSegTodoRiesgo: 0,
                        nvCapital: 0,
                        nvInteresCorriente: 0,
                        nvInteresMoratorio: 0,
                        nvSegVida: 0,
                        nvSegTodoRiesgo: 0
                    }


                    local.fechaTx = formatted_date
                    local.vlrTx = formatter.format(local.vlrTx)
                    local.vlrAplicadoCapital = formatter.format(this.toFixed(local.vlrAplicadoCapital, 0))
                    local.vlrAplicadoIntCte = formatter.format(this.toFixed(local.vlrAplicadoIntCte, 0))
                    local.vlrAplicadoIntMora = formatter.format(this.toFixed(local.vlrAplicadoIntMora, 0))
                    local.vlrAplicadoSegVida = formatter.format(this.toFixed(local.vlrAplicadoSegVida, 0))
                    local.vlrAplicadoSegTodoRiesgo = formatter.format(this.toFixed(local.vlrAplicadoSegTodoRiesgo, 0))

                    localData.siCapital = formatter.format(this.state.liquidacionesData[i].capital)

                    localData.moInteresCorriente = formatter.format(this.toFixed(this.state.transaccionesData[i].genInteresCorriente, 0))
                    localData.moInteresMoratorio = formatter.format(this.toFixed(this.state.transaccionesData[i].genInteresMoratorio, 0))
                    localData.moSegVida = formatter.format(this.toFixed(this.state.transaccionesData[i].genSeguroVida, 0))
                    localData.moSegTodoRiesgo = formatter.format(this.toFixed(this.state.transaccionesData[i].genSeguroTodoRiesgo, 0))

                    localData.apCapital = this.state.transaccionesData[i].vlrAplicadoCapital
                    localData.apInteresCorriente = this.state.transaccionesData[i].vlrAplicadoIntCte
                    localData.apInteresMoratorio = this.state.transaccionesData[i].vlrAplicadoIntMora
                    localData.apSegVida = this.state.transaccionesData[i].vlrAplicadoSegVida
                    localData.apSegTodoRiesgo = this.state.transaccionesData[i].vlrAplicadoSegTodoRiesgo

                    localData.nvCapital = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].capital, 0))
                    localData.nvInteresCorriente = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].interesCorriente, 0))
                    localData.nvInteresMoratorio = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].interesMoratorio, 0))
                    localData.nvSegVida = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].segVida, 0))
                    localData.nvSegTodoRiesgo = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].segTodoRiesgo, 0))

                    if (this.state.transaccionesData[i].mesesEnMora != 0) {
                        localData.siInteresCorriente = formatter.format(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvInteresCorriente)
                        localData.siInteresMoratorio = formatter.format(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvInteresMoratorio)
                        localData.siSegVida = formatter.format(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvSegVida)
                        localData.siSegTodoRiesgo = formatter.format(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvSegTodoRiesgo)
                    } else {
                        localData.siInteresCorriente = formatter.format(this.state.liquidacionesData[i].interesCorriente)
                        localData.siInteresMoratorio = formatter.format(this.state.liquidacionesData[i].interesMoratorio)
                        localData.siSegVida = formatter.format(this.state.liquidacionesData[i].segVida)
                        localData.siSegTodoRiesgo = formatter.format(this.state.liquidacionesData[i].segTodoRiesgo)
                    }


                    if (this.state.moneda == "uvr") {

                        var localDataUVR = {
                            siCapital: 0,
                            siInteresCorriente: 0,
                            siInteresMoratorio: 0,
                            siSegVida: 0,
                            siSegTodoRiesgo: 0,
                            moInteresCorriente: 0,
                            moInteresMoratorio: 0,
                            moSegVida: 0,
                            moSegTodoRiesgo: 0,
                            apCapital: 0,
                            apInteresCorriente: 0,
                            apInteresMoratorio: 0,
                            apSegVida: 0,
                            apSegTodoRiesgo: 0,
                            nvCapital: 0,
                            nvInteresCorriente: 0,
                            nvInteresMoratorio: 0,
                            nvSegVida: 0,
                            nvSegTodoRiesgo: 0
                        }

                        localDataUVR.siCapital = formatter.format(this.toFixed(this.state.liquidaciones[i].capitalUVR, 0))
                        localDataUVR.moInteresCorriente = formatter.format(this.toFixed(this.state.transaccionesData[i].genInteresCorrienteUVR, 0))
                        localDataUVR.moInteresMoratorio = formatter.format(this.toFixed(this.state.transaccionesData[i].genInteresMoratorioUVR, 0))
                        localDataUVR.moSegVida = formatter.format(this.state.transaccionesData[i].genSeguroVida)
                        localDataUVR.moSegTodoRiesgo = formatter.format(this.state.transaccionesData[i].genSeguroTodoRiesgo)

                        localDataUVR.apCapital = formatter.format(this.toFixed(this.state.transaccionesData[i].vlrAplicadoCapitalUVR, 0))
                        localDataUVR.apInteresCorriente = formatter.format(this.toFixed(this.state.transaccionesData[i].vlrAplicadoIntCteUVR, 0))
                        localDataUVR.apInteresMoratorio = formatter.format(this.toFixed(this.state.transaccionesData[i].vlrAplicadoIntMoraUVR, 0))
                        localDataUVR.apSegVida = this.state.transaccionesData[i].vlrAplicadoSegVida
                        localDataUVR.apSegTodoRiesgo = this.state.transaccionesData[i].vlrAplicadoSegTodoRiesgo
                        localDataUVR.nvCapital = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].capitalUVR, 0))
                        localDataUVR.nvInteresCorriente = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].interesCorrienteUVR, 0))
                        localDataUVR.nvInteresMoratorio = formatter.format(this.toFixed(this.state.liquidacionesData[i + 1].interesMoratorioUVR, 0))
                        localDataUVR.nvSegVida = formatter.format(this.state.liquidacionesData[i + 1].segVida)
                        localDataUVR.nvSegTodoRiesgo = formatter.format(this.state.liquidacionesData[i + 1].segTodoRiesgo)

                        if (this.state.transaccionesData[i].mesesEnMora != 0) {
                            localDataUVR.siInteresCorriente = formatter.format(this.toFixed(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvInteresCorrienteUVR, 0))
                            localDataUVR.siInteresMoratorio = formatter.format(this.toFixed(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvInteresMoratorioUVR, 0))
                            localDataUVR.siSegVida = formatter.format(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvSegVida)
                            localDataUVR.siSegTodoRiesgo = formatter.format(this.state.transaccionesData[i].dataMesesEnMora[this.state.transaccionesData[i].dataMesesEnMora.length - 1].nvSegTodoRiesgo)
                        } else {
                            localDataUVR.siInteresCorriente = formatter.format(this.toFixed(this.state.liquidacionesData[i].interesCorrienteUVR, 0))
                            localDataUVR.siInteresMoratorio = formatter.format(this.toFixed(this.state.liquidacionesData[i].interesMoratorioUVR, 0))
                            localDataUVR.siSegVida = formatter.format(this.state.liquidacionesData[i].segVida)
                            localDataUVR.siSegTodoRiesgo = formatter.format(this.state.liquidacionesData[i].segTodoRiesgo)
                        }
                        detallesDataUVR.push(localDataUVR)
                    }

                    detallesData.push(localData)
                    transaccionesData[i] = local
                }

                var totalTx = []
                var totalTxUVR = []

                for (let i = 0; i < this.state.transaccionesData.length; i++) {
                    var localTxAbs = [];
                    var localOp = [];
                    var localTxAbsUVR = [];
                    var localOpUVR = [];

                    localOp.push(detallesData[i].siCapital)
                    localOp.push(detallesData[i].siInteresCorriente)
                    localOp.push(detallesData[i].siInteresMoratorio)
                    localOp.push(detallesData[i].siSegVida)
                    localOp.push(detallesData[i].siSegTodoRiesgo)
                    localTxAbs.push(localOp)

                    var localOp = [];
                    localOp.push(detallesData[i].moInteresCorriente)
                    localOp.push(detallesData[i].moInteresMoratorio)
                    localOp.push(detallesData[i].moSegVida)
                    localOp.push(detallesData[i].moSegTodoRiesgo)
                    localTxAbs.push(localOp)

                    var localOp = [];
                    localOp.push(detallesData[i].apCapital)
                    localOp.push(detallesData[i].apInteresCorriente)
                    localOp.push(detallesData[i].apInteresMoratorio)
                    localOp.push(detallesData[i].apSegVida)
                    localOp.push(detallesData[i].apSegTodoRiesgo)
                    localTxAbs.push(localOp)

                    var localOp = [];
                    localOp.push(detallesData[i].nvCapital)
                    localOp.push(detallesData[i].nvInteresCorriente)
                    localOp.push(detallesData[i].nvInteresMoratorio)
                    localOp.push(detallesData[i].nvSegVida)
                    localOp.push(detallesData[i].nvSegTodoRiesgo)
                    localTxAbs.push(localOp)

                    if (this.state.moneda == "uvr") {
                        localOpUVR.push(detallesDataUVR[i].siCapital)
                        localOpUVR.push(detallesDataUVR[i].siInteresCorriente)
                        localOpUVR.push(detallesDataUVR[i].siInteresMoratorio)
                        localOpUVR.push(detallesDataUVR[i].siSegVida)
                        localOpUVR.push(detallesDataUVR[i].siSegTodoRiesgo)
                        localTxAbsUVR.push(localOpUVR)

                        var localOpUVR = [];
                        localOpUVR.push(detallesDataUVR[i].moInteresCorriente)
                        localOpUVR.push(detallesDataUVR[i].moInteresMoratorio)
                        localOpUVR.push(detallesDataUVR[i].moSegVida)
                        localOpUVR.push(detallesDataUVR[i].moSegTodoRiesgo)
                        localTxAbsUVR.push(localOpUVR)

                        var localOpUVR = [];
                        localOpUVR.push(detallesDataUVR[i].apCapital)
                        localOpUVR.push(detallesDataUVR[i].apInteresCorriente)
                        localOpUVR.push(detallesDataUVR[i].apInteresMoratorio)
                        localOpUVR.push(detallesDataUVR[i].apSegVida)
                        localOpUVR.push(detallesDataUVR[i].apSegTodoRiesgo)
                        localTxAbsUVR.push(localOpUVR)

                        var localOpUVR = [];
                        localOpUVR.push(detallesDataUVR[i].nvCapital)
                        localOpUVR.push(detallesDataUVR[i].nvInteresCorriente)
                        localOpUVR.push(detallesDataUVR[i].nvInteresMoratorio)
                        localOpUVR.push(detallesDataUVR[i].nvSegVida)
                        localOpUVR.push(detallesDataUVR[i].nvSegTodoRiesgo)
                        localTxAbsUVR.push(localOpUVR)

                        totalTxUVR.push(localTxAbsUVR)
                    }

                    totalTx.push(localTxAbs)
                }

                var resTx = [];
                var resTxUVR = [];


                for (let b = 1; b < this.state.transaccionesData.length + 1; b++) {
                    var localTx = [];
                    localTx.push(formatter.format(this.state.liquidacionesData[b].capital))
                    localTx.push(formatter.format(this.state.liquidacionesData[b].interesCorriente))
                    localTx.push(formatter.format(this.state.liquidacionesData[b].interesMoratorio))
                    localTx.push(formatter.format(this.state.liquidacionesData[b].segVida))
                    localTx.push(formatter.format(this.state.liquidacionesData[b].segTodoRiesgo))

                    if (this.state.moneda == "uvr") {
                        var localTxUVR = [];
                        localTxUVR.push(formatter.format(this.toFixed(this.state.liquidacionesData[b].capitalUVR, 2)))
                        localTxUVR.push(formatter.format(this.toFixed(this.state.liquidacionesData[b].interesCorrienteUVR, 2)))
                        localTxUVR.push(formatter.format(this.toFixed(this.state.liquidacionesData[b].interesMoratorioUVR, 2)))
                        localTxUVR.push(formatter.format(this.state.liquidacionesData[b].segVida))
                        localTxUVR.push(formatter.format(this.state.liquidacionesData[b].segTodoRiesgo))
                        resTxUVR.push(localTxUVR)

                    }
                    resTx.push(localTx)
                }

                var proyeccionAux = this.state.proyeccionData
                for (let i = 0; i < proyeccionAux.length; i++) {
                    var local = proyeccionAux[i]
                    var fechaIni = new Date(local.fechaIni);
                    var fechaFin = new Date(local.fechaFin);
                    local.periodo = fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) + " " + months[fechaIni.getMonth()] + " " + fechaIni.getFullYear() + " al " + fechaFin.getDate() + " de " + months[fechaFin.getMonth()] + " " + fechaFin.getFullYear()

                    const formatter = new Intl.NumberFormat('en-US', {
                        currency: 'USD',
                    })
                    local.capital = formatter.format(local.capital)
                    local.interes = formatter.format(local.interes)
                    local.vlrCuota = formatter.format(local.vlrCuota)
                    local.segVida = formatter.format(local.segVida)
                    local.segTodoRiesgo = formatter.format(local.segTodoRiesgo)
                    if (this.state.moneda == "uvr") {
                        local.vlrCapitalUVR = formatter.format(this.toFixed(local.vlrCapitalUVR, 2))
                        local.vlrInteresUVR = formatter.format(this.toFixed(local.vlrInteresUVR, 2))
                        local.vlrCuotaUVR = formatter.format(this.toFixed(local.vlrCuotaUVR, 2))
                    }
                    proyeccionAux[i] = local
                }


                var liquidacionesAux = [];
                var liquidacionesAuxUVR = [];

                var liquidacionesData = this.state.liquidacionesData
                var mesSalidaMora = []
                var pagosPorCreditos = [];
                var iteradorPagos = [];

                var sumMoras = 0
                var sumHasPagos = 0
                for (let i = 0; i < this.state.transaccionesData.length; i++) {
                    sumMoras = sumMoras + this.state.transaccionesData[i].mesesEnMora
                    if (this.state.transaccionesData[i].mesesEnMora != 0) {
                        for (let j = 0; j < this.state.transaccionesData[i].mesesEnMora; j++) {
                            mesSalidaMora.push(i)
                        }
                    }
                }

                var moved = false
                var fechaRef;
                if ((this.state.liquidaciones[0].interesCorriente != 0 || this.state.liquidaciones[0].interesMoratorio != 0 || this.state.liquidaciones[0].segVida != 0 || this.state.liquidaciones[0].segTodoRiesgo != 0)) {
                    for (let i = 0; i < this.state.proyeccionData.length; i++) {
                        if (this.state.fechaDesembolso >= new Date(this.state.proyeccionData[i].fechaIni) && this.state.fechaDesembolso <= new Date(this.state.proyeccionData[i].fechaFin)) {
                            fechaRef = this.state.proyeccionData[i];
                            moved = true
                        }
                    }
                }

                if (moved == false) {
                    for (let i = 0; i < this.state.proyeccionData.length; i++) {
                        var acum = 0;
                        for (let j = 0; j < this.state.transaccionesData.length; j++) {
                            if (this.state.transaccionesData[j].cuota == i) {
                                acum++;
                            }
                        }
                        pagosPorCreditos.push(acum);
                    }
                } else {
                    for (let i = fechaRef.noCuota; i < this.state.proyeccionData.length; i++) {
                        var acum = 0;
                        for (let j = 0; j < this.state.transaccionesData.length; j++) {
                            if (this.state.transaccionesData[j].cuota == i) {
                                acum++;
                            }
                        }
                        pagosPorCreditos.push(acum);
                    }
                }


                for (let i = 0; i < this.state.proyeccionData.length; i++) {
                    if (this.state.proyeccionData[i].presentaPagos) {
                        sumHasPagos++
                    }
                }

                var cntFilas = sumHasPagos + sumMoras
                var liquidacion = {
                    periodo: null,
                    pagosTotal: 0,
                    capital: formatter.format(this.state.liquidacionesData[0].capital),
                    interesCorriente: formatter.format(this.state.liquidacionesData[0].interesCorriente),
                    interesMoratorio: formatter.format(this.state.liquidacionesData[0].interesMoratorio),
                    segVida: formatter.format(this.state.liquidacionesData[0].segVida),
                    segTodoRiesgo: formatter.format(this.state.liquidacionesData[0].segTodoRiesgo)
                }
                liquidacionesAux.push(liquidacion)


                if (this.state.moneda == "uvr") {
                    var liquidacionUVR = {
                        periodo: null,
                        pagosTotal: 0,
                        capital: formatter.format(this.toFixed(this.state.liquidacionesData[0].capitalUVR, 2)),
                        interesCorriente: formatter.format(this.toFixed(this.state.liquidacionesData[0].interesCorrienteUVR, 2)),
                        interesMoratorio: formatter.format(this.toFixed(this.state.liquidacionesData[0].interesMoratorioUVR, 2)),
                        segVida: formatter.format(this.toFixed(this.state.liquidacionesData[0].segVida, 2)),
                        segTodoRiesgo: formatter.format(this.toFixed(this.state.liquidacionesData[0].segTodoRiesgo, 2)),
                    }
                    liquidacionesAuxUVR.push(liquidacionUVR)
                }

                if (this.state.moneda == "pesos") {

                    var cntMora = 0;
                    var moraLocal = 0;
                    var cntLiquidacion = 0;
                    var itPagos = 1;

                    for (let i = 0; i < cntFilas; i++) {
                        var cuotaLocal = this.state.proyeccionData[i]
                        if (moved == true) {
                            var cuotaLocal = this.state.proyeccionData[i + fechaRef.noCuota]
                        }
                        var localSum = 0;
                        var localSumUVR = 0
                        var liquidacion = {
                            periodo: 0,
                            pagosTotal: 0,
                            capital: 0,
                            interesCorriente: 0,
                            interesMoratorio: 0,
                            segVida: 0,
                            segTodoRiesgo: 0
                        }

                        var fechaIni = new Date(cuotaLocal.fechaIni);
                        var fechaFin = new Date(cuotaLocal.fechaFin);
                        liquidacion.periodo = fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) + " " + months[fechaIni.getMonth()] + " " + fechaIni.getFullYear() + " al " + fechaFin.getDate() + " de " + months[fechaFin.getMonth()] + " " + fechaFin.getFullYear()

                        const formatter = new Intl.NumberFormat('en-US', {
                            currency: 'USD',
                        })

                        if (this.state.liquidaciones[0].interesCorriente != 0 || this.state.liquidaciones[0].interesMoratorio != 0 || this.state.liquidaciones[0].segVida != 0 || this.state.liquidaciones[0].segTodoRiesgo != 0) {
                            for (let j = 0; j < this.state.transaccionesData.length; j++) {
                                if (this.state.transaccionesData[j].cuota == i + fechaRef.noCuota) {
                                    localSum = localSum + parseFloat(this.state.transaccionesData[j].vlrTx.replace(/,/g, ''))
                                }
                            }
                        } else {
                            for (let j = 0; j < this.state.transaccionesData.length; j++) {
                                if (this.state.transaccionesData[j].cuota == i) {
                                    localSum = localSum + parseFloat(this.state.transaccionesData[j].vlrTx.replace(/,/g, ''))
                                }
                            }
                        }

                        if (!cuotaLocal.presentaPagos) {
                            iteradorPagos[i] = 0
                            liquidacion.capital = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvCapital)
                            liquidacion.interesCorriente = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvInteresCorriente)
                            liquidacion.interesMoratorio = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvInteresMoratorio)
                            liquidacion.segVida = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvSegVida)
                            liquidacion.segTodoRiesgo = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvSegTodoRiesgo)
                            moraLocal++;
                            cntMora++;
                        } else {
                            iteradorPagos[i] = itPagos++
                            cntLiquidacion = cntLiquidacion + pagosPorCreditos[i];
                            liquidacion.capital = formatter.format(liquidacionesData[cntLiquidacion].capital)
                            liquidacion.interesCorriente = formatter.format(liquidacionesData[cntLiquidacion].interesCorriente)
                            liquidacion.interesMoratorio = formatter.format(liquidacionesData[cntLiquidacion].interesMoratorio)
                            liquidacion.segVida = formatter.format(liquidacionesData[cntLiquidacion].segVida)
                            liquidacion.segTodoRiesgo = formatter.format(liquidacionesData[cntLiquidacion].segTodoRiesgo)
                            moraLocal = 0;
                        }

                        liquidacion.pagosTotal = formatter.format(localSum)
                        liquidacionesAux.push(liquidacion)

                        if (this.state.moneda == "uvr") {
                            liquidacionUVR.pagosTotal = formatter.format(localSumUVR)
                            liquidacionesAuxUVR.push(liquidacionUVR)
                        }
                    }
                } else {

                    var cntMora = 0;
                    var moraLocal = 0;
                    var cntLiquidacion = 0;
                    var itPagos = 1;

                    for (let i = 0; i < cntFilas; i++) {

                        var cuotaLocal = this.state.proyeccionData[i]
                        if (moved == true) {
                            var cuotaLocal = this.state.proyeccionData[i + fechaRef.noCuota]
                        }
                        var localSum = 0;
                        var localSumUVR = 0
                        var liquidacion = {
                            periodo: 0,
                            pagosTotal: 0,
                            capital: 0,
                            interesCorriente: 0,
                            interesMoratorio: 0,
                            segVida: 0,
                            segTodoRiesgo: 0
                        }
                        var liquidacionUVR = {
                            periodo: 0,
                            pagosTotal: 0,
                            capital: 0,
                            interesCorriente: 0,
                            interesMoratorio: 0,
                            segVida: 0,
                            segTodoRiesgo: 0
                        }

                        var fechaIni = new Date(cuotaLocal.fechaIni);
                        var fechaFin = new Date(cuotaLocal.fechaFin);
                        liquidacion.periodo = fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) + " " + months[fechaIni.getMonth()] + " " + fechaIni.getFullYear() + " al " + fechaFin.getDate() + " de " + months[fechaFin.getMonth()] + " " + fechaFin.getFullYear()
                        liquidacionUVR.periodo = liquidacion.periodo

                        const formatter = new Intl.NumberFormat('en-US', {
                            currency: 'USD',
                        })

                        if (this.state.liquidaciones[0].interesCorriente != 0 || this.state.liquidaciones[0].interesMoratorio != 0 || this.state.liquidaciones[0].segVida != 0 || this.state.liquidaciones[0].segTodoRiesgo != 0) {
                            for (let j = 0; j < this.state.transaccionesData.length; j++) {
                                if (this.state.transaccionesData[j].cuota == i + fechaRef.noCuota) {
                                    localSum = localSum + parseFloat(this.state.transaccionesData[j].vlrTx.replace(/,/g, ''))
                                    localSumUVR = localSumUVR + this.state.transaccionesData[j].vlrTxUVR

                                }
                            }
                        } else {
                            for (let j = 0; j < this.state.transaccionesData.length; j++) {
                                if (this.state.transaccionesData[j].cuota == i) {
                                    localSum = localSum + parseFloat(this.state.transaccionesData[j].vlrTx.replace(/,/g, ''))
                                    localSumUVR = localSumUVR + this.state.transaccionesData[j].vlrTxUVR

                                }
                            }
                        }

                        if (!cuotaLocal.presentaPagos) {
                            iteradorPagos[i] = 0
                            liquidacion.capital = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvCapital)
                            liquidacion.interesCorriente = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvInteresCorriente)
                            liquidacion.interesMoratorio = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvInteresMoratorio)
                            liquidacion.segVida = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvSegVida)
                            liquidacion.segTodoRiesgo = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvSegTodoRiesgo)

                            liquidacionUVR.capital = formatter.format(this.toFixed(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvCapitalUVR, 2))
                            liquidacionUVR.interesCorriente = formatter.format(this.toFixed(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvInteresCorrienteUVR, 2))
                            liquidacionUVR.interesMoratorio = formatter.format(this.toFixed(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvInteresMoratorioUVR, 2))
                            liquidacionUVR.segVida = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvSegVida)
                            liquidacionUVR.segTodoRiesgo = formatter.format(transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[moraLocal].nvSegTodoRiesgo)

                            moraLocal++;
                            cntMora++;
                        } else {
                            iteradorPagos[i] = itPagos++
                            cntLiquidacion = cntLiquidacion + pagosPorCreditos[i];
                            liquidacion.capital = formatter.format(liquidacionesData[cntLiquidacion].capital)
                            liquidacion.interesCorriente = formatter.format(liquidacionesData[cntLiquidacion].interesCorriente)
                            liquidacion.interesMoratorio = formatter.format(liquidacionesData[cntLiquidacion].interesMoratorio)
                            liquidacion.segVida = formatter.format(liquidacionesData[cntLiquidacion].segVida)
                            liquidacion.segTodoRiesgo = formatter.format(liquidacionesData[cntLiquidacion].segTodoRiesgo)

                            liquidacionUVR.capital = formatter.format(this.toFixed(liquidacionesData[cntLiquidacion].capitalUVR, 2))
                            liquidacionUVR.interesCorriente = formatter.format(this.toFixed(liquidacionesData[cntLiquidacion].interesCorrienteUVR, 2))
                            liquidacionUVR.interesMoratorio = formatter.format(this.toFixed(liquidacionesData[cntLiquidacion].interesMoratorioUVR, 2))
                            liquidacionUVR.segVida = formatter.format(liquidacionesData[cntLiquidacion].segVida)
                            liquidacionUVR.segTodoRiesgo = formatter.format(liquidacionesData[cntLiquidacion].segTodoRiesgo)

                            moraLocal = 0;
                        }

                        liquidacion.pagosTotal = formatter.format(localSum)
                        liquidacionUVR.pagosTotal = formatter.format(this.toFixed(localSumUVR, 2))

                        liquidacionesAux.push(liquidacion)
                        liquidacionesAuxUVR.push(liquidacionUVR)

                    }
                }

                axios
                    .get(apiMethods.GET_CREDITO_ID + search, {
                        headers: {
                            Authorization: actUsrToken
                        }
                    })
                    .then(response => {
                        this.setState({
                            liquidacionesDataUVR: liquidacionesAuxUVR,
                            liquidacionesData: liquidacionesAux,
                            liquidaciones: response.data.credits.liquidaciones,
                            proyeccionData: proyeccionAux,
                            proyeccion: response.data.credits.proyeccion,
                            transaccionesData: transaccionesData,
                            transacciones: response.data.credits.transacciones,
                            pagosPorCreditos: pagosPorCreditos,
                            detalleData: totalTx,
                            detalleDataUVR: totalTxUVR,
                            resPeriodo: resTx,
                            resPeriodoUVR: resTxUVR,
                            iteradorPagos: iteradorPagos
                        })
                    })
            })

    }

    setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    showData = e => {
        this.setState({ excelCreated: false, periodicReportDialog: false, startDateSelected: false, startDate: new Date(), endDate: new Date() })
    }

    setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    fixTimeZone(originalDate) {
        if (originalDate) {
            let dateString = originalDate.toISOString();
            if (dateString.charAt(12) != 0 || dateString.charAt(11) != 5) {
                dateString = this.setCharAt(dateString, 12, "5")
                dateString = this.setCharAt(dateString, 11, "0")
            }
            return new Date(dateString);
        }
    }

    downloadManagementReport = (e) => {
        let actUsrToken = window.sessionStorage.getItem('localToken');
        Swal.fire({ title: "Cargando", allowOutsideClick: false });
        Swal.showLoading();
        axios
            .post(
                apiMethods.INFORME_COMERCIAL,
                {
                    initDate: ("0" + new Date(this.state.startDate).getDate()).slice(-2) + "-" + ("0" + (new Date(this.state.startDate).getMonth() + 1)).slice(-2) + "-" +
                        new Date(this.state.startDate).getFullYear(),
                    endDate: ("0" + new Date(this.state.endDate).getDate()).slice(-2) + "-" + ("0" + (new Date(this.state.endDate).getMonth() + 1)).slice(-2) + "-" +
                        new Date(this.state.endDate).getFullYear(),
                },
                {
                    headers: {
                        Authorization: actUsrToken,
                    },
                }
            )
            .then(response => {
                var filtered = response.data.message.filter(function (el) {
                    return el != null;
                });
                Swal.close();
                this.setState({ informeGerencialData: filtered, startDate: new Date(), endDate: new Date(), excelCreated: true })
            })
    }

    generatePDFCredit = e => {
        e.preventDefault()
        Swal.fire({ title: "Cargando", allowOutsideClick: false });
        Swal.showLoading();
        let actUsrToken = window.sessionStorage.getItem('localToken');
        var idCredito = this.state.selectedCredit;
        if (idCredito) {
            this.startComponent(e, idCredito)
            axios
                .get(apiMethods.GET_CREDITO_ID + idCredito, {
                    headers: {
                        Authorization: actUsrToken
                    }
                })
                .then(response => {
                    var auxAprobacion = this.fixTimeZone(new Date())
                    var auxGiro = this.fixTimeZone(new Date())
    
                    if (response.data.credits.fechaAprobacion == null) {
                        auxAprobacion = this.fixTimeZone(new Date())
                    } else {
                        auxAprobacion = this.fixTimeZone(new Date(response.data.credits.fechaAprobacion))
                    }
                    if (response.data.credits.fechaDesembolso == null) {
                        auxGiro = null
                    } else {
                        auxGiro = this.fixTimeZone(new Date(response.data.credits.fechaDesembolso))
                        auxGiro = this.fixTimeZone(new Date(auxGiro.getTime() + Math.abs(auxGiro.getTimezoneOffset() * 60000)));
                    }
    
                    var vlrInicialAux = response.data.credits.vlrInicial
                    const formatter = new Intl.NumberFormat('en-US', {
                        currency: 'USD',
                    })
                    vlrInicialAux = formatter.format(vlrInicialAux)
                    var vlrCapitalAux = response.data.credits.vlrCapital
                    var vlrInteresAux = response.data.credits.vlrInteres
                    var vlrInteresMoraAux = response.data.credits.vlrInteresMora
                    var vlrSegVidaAux = response.data.credits.saldoSegVida
                    var vlrSegTodoRiesgoAux = response.data.credits.saldoSegTodoRiesgo
    
                    vlrCapitalAux = formatter.format(vlrCapitalAux)
                    vlrInteresAux = formatter.format(vlrInteresAux)
                    vlrInteresMoraAux = formatter.format(vlrInteresMoraAux)
                    vlrSegVidaAux = formatter.format(vlrSegVidaAux)
                    vlrSegTodoRiesgoAux = formatter.format(vlrSegTodoRiesgoAux)
    
                    if (response.data.credits.fechaInicial) {
                        var auxFechaInicial = response.data.credits.fechaInicial.replace(/-/g, '/')
                    }
    
                    this.setState({
                        deudores: response.data.credits.deudores,
                        vlrCapital: vlrCapitalAux,
                        plazo: response.data.credits.plazo,
                        moneda: response.data.credits.moneda,
                        estado: response.data.credits.estado,
                        financialCode: response.data.credits.financialCode,
                        fechaInicial: auxFechaInicial,
                        fechaAprobacion: auxAprobacion,
                        fechaDesembolso: auxGiro,
                        transaccionesData: response.data.credits.transacciones,
                        vlrInicial: vlrInicialAux,
                        id: response.data.credits.id,
                        garantia: response.data.credits.garantia,
                        amortizacion: response.data.credits.amortizacion,
                        interes: response.data.credits.interes,
                        auxProxCorteSave: response.data.credits.fechaProxCorte,
                        auxProxCorte: response.data.credits.fechaProxCorte,
                        interesMoratorio: response.data.credits.interesMoratorio,
                        vlrInteres: vlrInteresAux,
                        vlrInteresMora: vlrInteresMoraAux,
                        liquidacionesResp: response.data.credits.liquidaciones,
                        saldoSegVida: vlrSegVidaAux,
                        saldoSegTodoRiesgo: vlrSegTodoRiesgoAux,
                        seguroVida: response.data.credits.seguroVida,
                        seguroTodoRiesgo: response.data.credits.seguroTodoRiesgo,
                        vlrSegVida: response.data.credits.vlrSegVida,
                        vlrSegTodoRiesgo: response.data.credits.vlrSegTodoRiesgo,
                        proyeccionData: response.data.credits.proyeccion,
                        proyeccion: response.data.credits.proyeccion,
                        liquidacionesData: response.data.credits.liquidaciones,
                        liquidaciones: response.data.credits.liquidaciones,
                        estadoReal: response.data.credits.estado,
                        vlrCuota: response.data.credits.vlrCuota,
                        pasoEdicionLocal: response.data.credits.pasoEdicion,
                        vlrInicialUVR: response.data.credits.vlrInicialUVR,
                        auxTransacciones: response.data.credits.transacciones,
                        transacciones: response.data.credits.transacciones,
                        auxFechaPagoReciente: response.data.credits.fechaPagoReciente
                    })
    
                    let pos = this.state.payments.map(function (e) { return e.credito; }).indexOf(idCredito);
                    var paymentDate = this.fixTimeZone(new Date(this.state.payments[pos].fecha))
                    var auxPeriodoSave;
    
                    this.state.proyeccion.forEach(element => {
                        if (this.fixTimeZone(new Date(element.fechaIni)) <= paymentDate && this.fixTimeZone(new Date(element.fechaFin)) >= paymentDate) {
                            auxPeriodoSave = element
                        }
                    })
    
    
                    const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DEC"];
    
                    var periodo = auxPeriodoSave.noCuota - 1
                    var savePeriodo = periodo
    
                    if (window.sessionStorage.getItem("initialPDF") == "true") {
                        var auxDia = this.createSelectItemsLiq()[0].props.value.substring(15, 17)
                        var auxMesText = this.createSelectItemsLiq()[0].props.value.substring(18, 21)
                        var auxMes;
                        var auxAnio = this.createSelectItemsLiq()[0].props.value.substring(22, 26)
                    } else {
                        var auxDia = paymentDate.getDate()
                        var auxMes;
                        var auxAnio = paymentDate.getFullYear()
                    }
    
                    auxMes = paymentDate.getMonth() + 1;
    
                    if (auxDia < 10) {
                        auxDia = '0' + auxDia;
                    }
                    if (auxMes < 10) {
                        auxMes = '0' + auxMes;
                    }
                    var parsedDateQuery = auxDia + '-' + auxMes + '-' + auxAnio
    
                    let auxIniStr = new Date(this.state.proyeccion[periodo].fechaIni).toISOString()
                    if (auxIniStr.charAt(12) != 0 || auxIniStr.charAt(11) != 5) {
                        auxIniStr = this.setCharAt(auxIniStr, 12, "5")
                        auxIniStr = this.setCharAt(auxIniStr, 11, "0")
                    }
                    var auxIni = new Date(auxIniStr)
    
                    let auxFinStr = new Date(this.state.proyeccion[periodo].fechaFin).toISOString()
                    if (auxFinStr.charAt(12) != 0 || auxFinStr.charAt(11) != 5) {
                        auxFinStr = this.setCharAt(auxFinStr, 12, "5")
                        auxFinStr = this.setCharAt(auxFinStr, 11, "0")
                    }
                    var auxFin = new Date(auxFinStr)
    
    
                    var fechasPeriodo = ("0" + (auxIni.getDate() + 1)).slice(-2) + " " + months[(auxIni.getMonth())] + " " + auxIni.getFullYear() + " al " + ("0" + auxFin.getDate()).slice(-2) + " " + months[(auxFin.getMonth())] + " " + auxFin.getFullYear();
    
                    var auxInit = this.fixTimeZone(new Date(this.state.transacciones[this.state.transacciones.length - 1].fechaTx))
                    auxInit.setMonth(auxInit.getMonth() + 1)
                    auxInit.setDate(this.state.fechaDesembolso.getDate())
    
                    var year = auxInit.getFullYear();
                    var month = auxInit.getMonth();
                    var dt = auxInit.getDate();
    
                    if (dt < 10) {
                        dt = '0' + dt;
                    }
                    if (month < 10) {
                        month = '0' + month;
                    }
                    var parsedDate = dt + '-' + month + '-' + year
    
                    axios
                        .get(apiMethods.GET_UVR_FECHA + parsedDate, {
                            headers: {
                                Authorization: actUsrToken
                            }
                        })
                        .then(response => {
                            var auxProxCorte = this.fixTimeZone(new Date(this.state.auxProxCorte));
    
                            var year = auxProxCorte.getFullYear();
                            var month = auxProxCorte.getMonth() + 1;
                            var dt = auxProxCorte.getDate();
    
                            if (dt < 10) {
                                dt = '0' + dt;
                            }
                            if (month < 10) {
                                month = '0' + month;
                            }
                            var parsedDate = auxDia + '-' + auxMes + '-' + auxAnio
    
                            var fechaPago = this.state.fechaPago
                            if (response.data.valor) {
                                var vlrUVRInitAux = response.data.uvr[0].valor;
                                var vlrUVRInit = parseFloat(vlrUVRInitAux)
                            }
    
                            axios
                                .get(apiMethods.GET_UVR_FECHA + parsedDateQuery, {
                                    headers: {
                                        Authorization: actUsrToken
                                    }
                                })
                                .then(response => {
                                    var fechaPago2 = this.state.fechaPago
                                    if (response.data.uvr || this.state.moneda == "pesos") {
    
                                        if (this.state.moneda == "uvr") {
                                            var vlrUVRInitAux2 = response.data.uvr[0].valor;
                                            var vlrUVRInit2 = parseFloat(vlrUVRInitAux2)
                                        }
    
    
                                        const formatter = new Intl.NumberFormat('en-US', {
                                            currency: 'USD',
                                        })
    
                                        var transacciones = this.state.transaccionesData
                                        var auxTxAbs = []
                                        var headers = ['Periodo', 'Pagos realizados en el periodo', 'Capital', 'Interés Corriente', 'Interés Moratorio', 'Seguro de vida', 'Seguro de incendio y todo riesgo'];
                                        var auxHeaders = []
    
                                        for (let i = 0; i < 7; i++) {
                                            var auxHeader = {
                                                text: headers[i],
                                                style: ['tableHeader']
                                            }
                                            auxHeaders.push(auxHeader)
                                        }
                                        auxTxAbs.push(auxHeaders)
    
                                        var clientes = []
    
                                        for (let i = 1; i < this.state.clientesData.length; i++) {
    
                                            var title = {
                                                lineHeight: 1,
                                                text: "APRECIADO CLIENTE",
                                                style: 'text'
                                            }
                                            clientes.push(title)
    
                                            var nombre = {
                                                lineHeight: 1,
                                                text: [this.state.clientesData[i].nombre],
                                                style: 'textBold',
                                            }
    
                                            clientes.push(nombre)
    
                                            var direccion = {
                                                lineHeight: 2,
                                                text: [this.state.clientesData[i].direccion],
                                                style: 'text'
                                            }
    
                                            clientes.push(direccion)
                                        }
    
                                        var paymentsHeader = [{
                                            text: 'PAGOS RECIBIDOS EN ESTE PERIODO',
                                            alignment: 'center',
                                            colSpan: 2,
                                            border: [false, false, false, false]
                                        }, {}]
    
                                        var paymentsBody = [];
    
                                        var neededPayments = []
                                        var neededPaymentsData = []
    
                                        var auxPeriodo = periodo;
    
                                        var fechaDesembolsoReal;
    
                                        if (this.state.liquidaciones[0].interesCorriente != 0 || this.state.liquidaciones[0].interesMoratorio != 0 || this.state.liquidaciones[0].segVida != 0 || this.state.liquidaciones[0].segTodoRiesgo != 0) {
                                            var sumMora = 0;
                                            var auxFecha = this.fixTimeZone(new Date(this.state.fechaInicial))
                                            fechaDesembolsoReal = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(auxFecha) + " de " + new Intl.DateTimeFormat('es', { month: 'long' }).format(auxFecha) + " " + new Intl.DateTimeFormat('en', { year: 'numeric' }).format(auxFecha);
    
                                            for (let i = 0; i < this.state.transacciones.length; i++) {
                                                sumMora = sumMora + this.state.transacciones[i].mesesEnMora
                                            }
    
                                            var cntCuotas = 0;
    
                                            for (let i = 0; i < this.state.liquidacionesData.length; i++) {
                                                if (this.state.liquidacionesData[i].pagosTotal != 0) {
                                                    cntCuotas++;
                                                }
                                            }
    
                                            if (cntCuotas == sumMora && this.state.transacciones.length > 1) {
                                                sumMora--;
                                            }
    
                                            var sum = 0
                                        } else {
                                            fechaDesembolsoReal = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(this.state.fechaDesembolso) + " de " + new Intl.DateTimeFormat('es', { month: 'long' }).format(this.state.fechaDesembolso) + " " + new Intl.DateTimeFormat('en', { year: 'numeric' }).format(this.state.fechaDesembolso);
                                        }
    
                                        var neededLiq = [];
                                        var sumMoraTx = 0;
    
                                        for (let i = 0; i <= periodo; i++) {
                                            if (this.state.proyeccion[i].presentaPagos == false) {
                                                sumMoraTx++;
                                            }
                                        }
    
                                        if (this.state.proyeccion[0].liquidado == false) {
                                            sumMoraTx--;
                                        }
    
                                        if (sumMoraTx < 0) {
                                            sumMoraTx = 0;
                                        }
    
                                        var cntCero = 0;
    
    
                                        for (let i = 0; i < this.state.transacciones.length; i++) {
                                            if (this.state.transacciones[i].vlrTx == 0) {
                                                cntCero++;
                                            }
                                        }
    
                                        periodo = periodo - cntCero;
    
                                        if (periodo < 0) {
                                            periodo = 0;
                                        }
    
                                        var genPago = this.state.transacciones[periodo - sumMoraTx]
    
                                        for (let i = 0; i < this.state.transacciones.length; i++) {
                                            if (this.state.proyeccionData[0].liquidado == true) {
                                                if (this.state.transacciones[i].cuota == savePeriodo) {
                                                    neededPayments.push(this.state.transacciones[i]);
                                                }
                                            } else {
                                                if (this.state.transacciones[i].cuota == savePeriodo) {
                                                    neededPayments.push(this.state.transacciones[i]);
                                                }
                                            }
    
                                        }
    
                                        var cero = false
                                        var normal = false
    
                                        for (let i = 0; i < neededPayments.length; i++) {
                                            if (neededPayments[i].vlrTx == 0) {
                                                cero = true
                                            } else {
                                                normal = true
                                            }
                                        }
    
                                        if (cero && normal) {
                                            for (let i = 0; i < neededPayments.length; i++) {
                                                if (neededPayments[i].vlrTx == 0) {
                                                    neededPayments.splice(i);
                                                }
                                            }
                                        }
    
                                        for (let i = 0; i < neededPayments.length; i++) {
                                            neededLiq.push(this.state.liquidaciones[neededPayments[i].id + 1])
                                        }
    
                                        var auxFechaFinPeriodo = this.fixTimeZone(new Date(this.state.proyeccion[neededPayments[0].cuota].fechaFin))
                                        auxFechaFinPeriodo.setMonth(auxFechaFinPeriodo.getMonth() + 1)
                                        var fechaCompleta = ("0" + auxFechaFinPeriodo.getDate()).slice(-2) + "/" + ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) + "/" + auxFechaFinPeriodo.getFullYear()
                                        var sumIntCteGen = 0;
                                        var sumIntMoraGen = 0;
                                        var sumIntCteGenUVR = 0;
                                        var sumIntMoraGenUVR = 0;
                                        var sumSegGen = 0;
    
                                        var sumIntCteApl = 0;
                                        var sumIntMoraApl = 0;
                                        var sumSegApl = 0;
                                        var sumCapApl = 0;
    
                                        var sumIntCteAplUVR = 0;
                                        var sumIntMoraAplUVR = 0;
                                        var sumCapAplUVR = 0;
    
                                        for (let i = 0; i < neededPaymentsData.length; i++) {
                                            sumIntCteGen = sumIntCteGen + neededPaymentsData[i].genInteresCorriente;
                                            sumIntMoraGen = sumIntMoraGen + neededPaymentsData[i].genInteresMoratorio;
                                            sumSegGen = sumSegGen + neededPaymentsData[i].genSeguroVida + neededPaymentsData[i].genSeguroTodoRiesgo;
    
                                            sumCapApl = sumCapApl + neededPaymentsData[i].vlrAplicadoCapital;
                                            sumIntCteApl = sumIntCteApl + neededPaymentsData[i].vlrAplicadoIntCte;
                                            sumIntMoraApl = sumIntMoraApl + neededPaymentsData[i].vlrAplicadoIntMora;
                                            sumSegApl = sumSegApl + neededPaymentsData[i].vlrAplicadoSegVida + neededPaymentsData[i].vlrAplicadoSegTodoRiesgo;
    
                                            if (this.state.moneda == "uvr") {
                                                sumIntCteGenUVR = sumIntCteGenUVR + neededPaymentsData[i].genInteresCorrienteUVR;
                                                sumIntMoraGenUVR = sumIntMoraGenUVR + neededPaymentsData[i].genInteresMoratorioUVR;
    
                                                sumCapAplUVR = sumCapAplUVR + neededPaymentsData[i].vlrAplicadoCapitalUVR;
                                                sumIntCteAplUVR = sumIntCteAplUVR + neededPaymentsData[i].vlrAplicadoIntCteUVR;
                                                sumIntMoraAplUVR = sumIntMoraAplUVR + neededPaymentsData[i].vlrAplicadoIntMoraUVR;
                                            }
                                        }
    
                                        var auxLiquidacionPrev;
                                        var auxLiquidacionPost;
    
                                        auxLiquidacionPost = this.state.liquidaciones[neededPayments[0].id + this.state.pagosPorCreditos[auxPeriodo - 1]]
    
                                        for (let i = 0; i < neededPayments.length; i++) {
                                            paymentsBody.push({
                                                Fecha: neededPayments[i].fechaTx,
                                                Monto: "$" + neededPayments[i].vlrTx
                                            })
                                        }
    
                                        if (this.state.moneda == "uvr") {
                                            var auxVlrCuota = this.state.proyeccion[0].vlrCuotaUVR * parseFloat(vlrUVRInit2)
    
                                        } else {
                                            var auxVlrCuota = this.state.proyeccion[0].vlrCuota
                                        }
    
                                        if (this.state.proyeccion[neededPayments[neededPayments.length - 1].cuota + 1]) {
                                            auxVlrCuota += parseFloat(this.state.proyeccion[neededPayments[neededPayments.length - 1].cuota + 1].segVida)
                                            auxVlrCuota += parseFloat(this.state.proyeccion[neededPayments[neededPayments.length - 1].cuota + 1].segTodoRiesgo)
                                        }
    
                                        if (this.state.moneda === "uvr") {
                                            var auxProy = 0;
                                            if (this.state.proyeccion[neededPayments[neededPayments.length - 1].cuota + 1]) {
                                                auxProy = parseFloat(this.state.proyeccion[neededPayments[neededPayments.length - 1].cuota + 1].vlrCapitalUVR);
                                            }
                                            var capitalEnMora = parseFloat(neededLiq[neededLiq.length - 1].capitalUVR) - auxProy
                                            capitalEnMora = parseFloat(vlrUVRInit2) * capitalEnMora
                                            if (capitalEnMora < 0) {
                                                capitalEnMora = 0;
                                            }
                                        } else {
    
                                            var auxProy = 0;
                                            if (this.state.proyeccion[neededPayments[neededPayments.length - 1].cuota + 1]) {
                                                auxProy = parseFloat(this.state.proyeccion[neededPayments[neededPayments.length - 1].cuota + 1].capital);
                                            }
                                            var capitalEnMora = parseFloat(neededLiq[neededLiq.length - 1].capital) - auxProy
    
                                            if (capitalEnMora < 0) {
                                                capitalEnMora = 0;
                                            }
                                        }
    
                                        if (this.state.moneda == "uvr") {
                                            var saldoMora = (parseFloat(capitalEnMora)) + (parseFloat(neededLiq[neededLiq.length - 1].interesCorrienteCuotaUVR) * parseFloat(vlrUVRInit2)) + (parseFloat(neededLiq[neededLiq.length - 1].interesMoratorioCuotaUVR) * parseFloat(vlrUVRInit2)) + parseFloat(neededLiq[neededLiq.length - 1].segVida) + parseFloat(neededLiq[neededLiq.length - 1].segTodoRiesgo)
                                        } else {
                                            var saldoMora = parseFloat(capitalEnMora) + (parseFloat(neededLiq[neededLiq.length - 1].interesCorrienteCuota)) + (parseFloat(neededLiq[neededLiq.length - 1].interesMoratorioCuota)) + (parseFloat(neededLiq[neededLiq.length - 1].segVida)) + (parseFloat(neededLiq[neededLiq.length - 1].segTodoRiesgo))
                                        }
    
                                        if (this.state.moneda == "uvr") {
                                            if (neededPayments[0].cuota <= parseFloat(this.state.plazo)) {
                                                if (saldoMora > 0) {
                                                    var textoMora = "Su obligación se encuentra en mora en $" + formatter.format(saldoMora) + " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " + ("0" + auxFechaFinPeriodo.getDate()).slice(-2) + "/" + ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) + "/" + auxFechaFinPeriodo.getFullYear() + " por un monto aproximado de $" + formatter.format(this.toFixed(auxVlrCuota, 0)) + "\n" + "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota."
                                                    var textoPaguese = "PAGUESE INMEDIATAMENTE"
                                                } else if (saldoMora == 0) {
                                                    var textoMora = "Su obligación está al día, su próxima cuota es del dia " + fechaCompleta + " por un monto de $" + formatter.format(this.toFixed(auxVlrCuota, 0))
                                                    var textoPaguese = "PAGUESE EL: " + ("0" + auxFechaFinPeriodo.getDate()).slice(-2) + "-" + ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) + "-" + auxFechaFinPeriodo.getFullYear()
                                                } else if (saldoMora < 0) {
                                                    var textoMora = "Su obligación está al día"
                                                } else if (this.proyeccion[this.proyeccion.length - 1].fechaFin < neededPayments[0].fechaTx) {
                                                    var textoMora = "Su obligación se encuentra en mora en $" + formatter.format(saldoMora) + " por favor ponerse al dia inmediatamente por un monto aproximado de $" + formatter.format(this.toFixed(auxVlrCuota, 0)) + "\n" + "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota."
                                                }
                                            } else {
                                                var textoMora = "Su obligación se encuentra en mora en $" + formatter.format(saldoMora) + " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " + ("0" + auxFechaFinPeriodo.getDate()).slice(-2) + "/" + ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) + "/" + auxFechaFinPeriodo.getFullYear();
                                                var textoPaguese = "PAGUESE INMEDIATAMENTE"
                                            }
                                            if (this.state.initialPDF) {
                                                var auxFecha = this.state.fechaDesembolso
                                                auxFecha.setMonth(auxFecha.getMonth() + 1)
                                                var textoMora = "Su obligación se encuentra al día, su próxima cuota programada es el dia " + ("0" + auxFecha.getDate()).slice(-2) + "/" + ("0" + (auxFecha.getMonth() + 1)).slice(-2) + "/" + auxFecha.getFullYear() + " por un monto aproximado de $" + formatter.format(this.toFixed(auxVlrCuota, 0)) + "\n" + "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota.";
                                                this.setState({ initialPDF: false })
                                            }
                                        } else {
                                            if (neededPayments[0].cuota <= parseFloat(this.state.plazo)) {
                                                if (saldoMora > 0) {
                                                    var textoMora = "Su obligación se encuentra en mora en $" + formatter.format(saldoMora) + " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " + ("0" + auxFechaFinPeriodo.getDate()).slice(-2) + "/" + ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) + "/" + auxFechaFinPeriodo.getFullYear() + " por un monto de $" + formatter.format(this.toFixed(auxVlrCuota, 0))
                                                    var textoPaguese = "PAGUESE INMEDIATAMENTE"
                                                } else if (saldoMora == 0) {
                                                    var textoMora = "Su obligación está al día, su próxima cuota es del dia " + fechaCompleta + " por un monto de $" + formatter.format(this.toFixed(auxVlrCuota, 0))
                                                    var textoPaguese = "PAGUESE EL: " + ("0" + auxFechaFinPeriodo.getDate()).slice(-2) + "-" + ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) + "-" + auxFechaFinPeriodo.getFullYear()
                                                } else if (saldoMora < 0) {
                                                    var textoMora = "Su obligación está al día"
                                                } else if (this.proyeccion[this.proyeccion.length - 1].fechaFin < neededPayments[0].fechaTx) {
                                                    var textoMora = "Su obligación se encuentra en mora en $" + formatter.format(saldoMora) + " por favor ponerse al dia inmediatamente por un monto de $" + formatter.format(this.toFixed(auxVlrCuota, 0))
                                                }
                                            } else {
                                                var textoMora = "Su obligación se encuentra en mora en $" + formatter.format(saldoMora) + " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " + ("0" + auxFechaFinPeriodo.getDate()).slice(-2) + "/" + ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) + "/" + auxFechaFinPeriodo.getFullYear();
                                                var textoPaguese = "PAGUESE INMEDIATAMENTE"
                                            }
                                            if (this.state.initialPDF) {
                                                var auxFecha = this.state.fechaDesembolso
                                                auxFecha.setMonth(auxFecha.getMonth() + 1)
                                                var textoMora = "Su obligación se encuentra al día, su próxima cuota programada es el dia " + ("0" + auxFecha.getDate()).slice(-2) + "/" + ("0" + (auxFecha.getMonth() + 1)).slice(-2) + "/" + auxFecha.getFullYear() + " por un monto de $" + formatter.format(this.toFixed(auxVlrCuota, 0));
                                                this.setState({ initialPDF: false })
                                            }
                                        }
    
    
                                        var liqTables = [];
                                        var cntNeededPayments = neededPayments[0].id
    
                                        for (let i = 0; i < neededPayments.length; i++) {
                                            var liqGen = [];
    
                                            var auxFecha = this.fixTimeZone(new Date(neededPayments[i].fechaTx))
                                            var textoFecha = ("0" + auxFecha.getDate()).slice(-2) + "-" + months[(auxFecha.getMonth())] + "-" + auxFecha.getFullYear()
                                            var auxNuevoCorriente = formatter.format(parseFloat(this.state.detalleData[cntNeededPayments][3][1].replace(/,/g, "")))
                                            var auxSegVida;
    
                                            if (i == 0 && neededPayments[0].id == 0 && this.state.detalleData[cntNeededPayments][0][3] == "0") {
                                                auxSegVida = formatter.format(this.state.liquidacionesResp[0].segVida)
                                            } else {
                                                auxSegVida = this.state.detalleData[cntNeededPayments][0][3]
                                            }
    
                                            if (i == 0 && neededPayments[0].id == 0 && this.state.detalleData[cntNeededPayments][0][4] == "0") {
                                                var auxSegTodoRiesgo = formatter.format(this.state.liquidacionesResp[0].segTodoRiesgo)
                                            } else {
                                                var auxSegTodoRiesgo = this.state.detalleData[cntNeededPayments][0][4]
                                            }
    
                                            if (this.state.moneda == "uvr") {
                                                var auxNuevoCorrienteUVR = formatter.format(parseFloat(this.state.detalleDataUVR[cntNeededPayments][3][1].replace(/,/g, "")))
                                                var saldoInicial = ['Saldo Inicial', this.state.detalleDataUVR[cntNeededPayments][0][0] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][0][0] + ")", this.state.detalleDataUVR[cntNeededPayments][0][1] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][0][1] + ")", this.state.detalleDataUVR[cntNeededPayments][0][2] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][0][2] + ")", "$" + auxSegVida, "$" + auxSegTodoRiesgo];
                                                var movimientos = ['Movimientos', '', this.state.detalleDataUVR[cntNeededPayments][1][0] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][1][0] + ")", this.state.detalleDataUVR[cntNeededPayments][1][1] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][1][1] + ")", "$" + this.state.detalleData[cntNeededPayments][1][2], "$" + this.state.detalleData[cntNeededPayments][1][3]];
                                                var aplicacion = ['Aplicación del pago', this.state.detalleDataUVR[cntNeededPayments][2][0] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][2][0] + ")", this.state.detalleDataUVR[cntNeededPayments][2][1] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][2][1] + ")", this.state.detalleDataUVR[cntNeededPayments][2][2] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][2][2] + ")", "$" + this.state.detalleData[cntNeededPayments][2][3], "$" + this.state.detalleData[cntNeededPayments][2][4]];
                                                var saldo = ['Nuevo Saldo', this.state.detalleDataUVR[cntNeededPayments][3][0] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][3][0] + ")", auxNuevoCorrienteUVR + " UVR" + " " + "($" + auxNuevoCorriente + ")", this.state.detalleDataUVR[cntNeededPayments][3][2] + " UVR" + " " + "($" + this.state.detalleData[cntNeededPayments][3][2] + ")", "$" + this.state.detalleData[cntNeededPayments][3][3], "$" + this.state.detalleData[cntNeededPayments][3][4]];
                                            } else {
                                                var saldoInicial = ['Saldo Inicial', "$" + this.state.detalleData[cntNeededPayments][0][0], "$" + this.state.detalleData[cntNeededPayments][0][1], "$" + this.state.detalleData[cntNeededPayments][0][2], "$" + auxSegVida, "$" + auxSegTodoRiesgo];
                                                var movimientos = ['Movimientos', '', "$" + this.state.detalleData[cntNeededPayments][1][0], "$" + this.state.detalleData[cntNeededPayments][1][1], "$" + this.state.detalleData[cntNeededPayments][1][2], "$" + this.state.detalleData[cntNeededPayments][1][3]];
                                                var aplicacion = ['Aplicación del pago', "$" + this.state.detalleData[cntNeededPayments][2][0], "$" + this.state.detalleData[cntNeededPayments][2][1], "$" + this.state.detalleData[cntNeededPayments][2][2], "$" + this.state.detalleData[cntNeededPayments][2][3], "$" + this.state.detalleData[cntNeededPayments][2][4]];
                                                var saldo = ['Nuevo Saldo', "$" + this.state.detalleData[cntNeededPayments][3][0], "$" + auxNuevoCorriente, "$" + this.state.detalleData[cntNeededPayments][3][2], "$" + this.state.detalleData[cntNeededPayments][3][3], "$" + this.state.detalleData[cntNeededPayments][3][4]];
                                            }
    
                                            if (this.state.moneda == "uvr") {
                                                var movimientosRes = ['Movimientos', '', formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesCorrienteCuotaGenUVR, 0)) + " UVR" + " " + "($" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesCorrienteCuotaGen, 0)) + ")", formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesMoratorioCuotaGenUVR, 0)) + " UVR" + " " + "($" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesMoratorioCuotaGen, 0)) + ")", "$0", "$0"];
                                                var saldoRes = ['Nuevo Saldo', formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].capitalUVR, 0)) + " UVR" + " " + "($" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].capitalProxCorte, 0)) + ")", formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesCorrienteCuotaUVR, 0)) + " UVR" + " " + "($" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesCorrienteCuota, 0)) + ")", formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesMoratorioCuotaUVR, 0)) + " UVR" + " " + "($" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesMoratorioCuota, 0)) + ")", "$" + this.state.detalleData[cntNeededPayments][3][3], "$" + this.state.detalleData[cntNeededPayments][3][4]];
                                            } else {
                                                var movimientosRes = ['Movimientos', '', "$" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesCorrienteCuotaGen, 0)), "$" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesMoratorioCuotaGen, 0)), "$0", "$0"];
                                                var saldoRes = ['Nuevo Saldo', "$" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].capital, 0)), "$" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesCorrienteCuota, 0)), "$" + formatter.format(this.toFixed(neededLiq[neededLiq.length - 1].interesMoratorioCuota, 0)), "$" + this.state.detalleData[cntNeededPayments][3][3], "$" + this.state.detalleData[cntNeededPayments][3][4]];
                                            }
    
                                            if (neededPayments.length > 1 && i == neededPayments.length - 1) {
                                                var auxTable = {
                                                    style: 'tableExample',
                                                    color: 'black',
                                                    margin: [0, 10, 0, 0],
                                                    table: {
                                                        body: [
                                                            ['', {
                                                                text: 'Capital',
                                                                style: 'textBoldTable'
                                                            }, {
                                                                    text: 'Interés Corriente',
                                                                    style: 'textBoldTable'
                                                                }, {
                                                                    text: 'Interés Moratorio',
                                                                    style: 'textBoldTable'
                                                                }, {
                                                                    text: 'Seguro de Vida',
                                                                    style: 'textBoldTable'
                                                                }, { text: 'Seguro de Incendio y Todo Riesgo', style: 'textBoldTable' }],
                                                            saldoInicial,
                                                            movimientos,
                                                            aplicacion,
                                                            saldo
                                                        ]
                                                    },
                                                    pageBreak: 'after'
                                                }
                                            } else {
                                                var auxTable = {
                                                    style: 'tableExample',
                                                    color: 'black',
                                                    margin: [0, 10, 0, 0],
                                                    table: {
                                                        body: [
                                                            ['', {
                                                                text: 'Capital',
                                                                style: 'textBoldTable'
                                                            }, {
                                                                    text: 'Interés Corriente',
                                                                    style: 'textBoldTable'
                                                                }, {
                                                                    text: 'Interés Moratorio',
                                                                    style: 'textBoldTable'
                                                                }, {
                                                                    text: 'Seguro de Vida',
                                                                    style: 'textBoldTable'
                                                                }, { text: 'Seguro de Incendio y Todo Riesgo', style: 'textBoldTable' }],
                                                            saldoInicial,
                                                            movimientos,
                                                            aplicacion,
                                                            saldo
                                                        ]
                                                    },
                                                }
                                            }
    
                                            var auxTableRes = {
                                                style: 'tableExample',
                                                color: 'black',
                                                margin: [0, 10, 0, 0],
                                                table: {
                                                    body: [
                                                        ['', { text: 'Capital', style: 'textBoldTable' }, {
                                                            text: 'Interés Corriente',
                                                            style: 'textBoldTable'
                                                        }, {
                                                                text: 'Interés Moratorio',
                                                                style: 'textBoldTable'
                                                            }, {
                                                                text: 'Seguro de Vida',
                                                                style: 'textBoldTable'
                                                            }, { text: 'Seguro de Incendio y Todo Riesgo', style: 'textBoldTable' }],
                                                        movimientosRes,
                                                        saldoRes
                                                    ]
                                                },
                                            }
                                            if (neededPayments[i].vlrTx != 0) {
                                                if (this.state.moneda == "uvr") {
                                                    var text = {
                                                        text: 'Pago ' + (i + 1) + ". " + formatter.format(this.toFixed(neededPayments[i].vlrTxUVR, 2)) + " UVR " + "($" + formatter.format(this.toFixed(neededPayments[i].vlrTx, 2)) + ")" + " el " + textoFecha,
                                                        style: 'headerPago',
                                                        margin: [0, 10, 0, 0],
                                                    };
                                                } else {
                                                    var text = {
                                                        text: 'Pago ' + (i + 1) + ". " + formatter.format(this.toFixed(neededPayments[i].vlrTx, 2)) + " el " + textoFecha,
                                                        style: 'headerPago',
                                                        margin: [0, 10, 0, 0],
                                                    };
                                                }
                                            } else {
                                                var text = {
                                                    text: '',
                                                    style: 'headerPago',
                                                    margin: [0, 10, 0, 0],
                                                };
                                            }
    
                                            cntNeededPayments++;
    
    
                                            liqGen.push(text);
                                            liqGen.push(auxTable);
                                            liqTables.push(liqGen)
                                        }
                                        var auxThis = this;
                                        var nombre = this.state.clientesData[0].nombre
                                        var direccion = this.state.clientesData[0].direccion
    
                                        var docDefinition = {
                                            pageMargins: [40, 280, 40, 200],
                                            header: function () {
                                                return [
                                                    {
                                                        margin: [0, 0, 0, 10],
                                                        canvas: [
                                                            {
                                                                type: 'rect',
                                                                x: -15,
                                                                y: -15,
                                                                w: 610,
                                                                h: 76,
                                                                color: '#e92128'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        margin: [0, 0, 0, 10],
                                                        canvas: [
                                                            {
                                                                type: 'rect',
                                                                x: -15,
                                                                y: -20,
                                                                w: 610,
                                                                h: 8,
                                                                color: '#B9B9B9'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        text: "INMOBILIARIA",
                                                        style: 'title1',
                                                        absolutePosition: { x: 305, y: 5 }
                                                    },
                                                    {
                                                        text: "MOBILIA",
                                                        style: 'title2',
                                                        absolutePosition: { x: 305, y: 27 }
                                                    },
                                                    {
                                                        width: 100,
                                                        image: logo,
                                                        alignment: 'center',
                                                        absolutePosition: { y: 6, x: -110 }
                                                    },
                                                    {
                                                        margin: [40, 0, 0, 0],
                                                        lineHeight: 1.3,
                                                        text: "ESTADO DE CUENTA",
                                                        style: 'textBold',
                                                    },
                                                    {
                                                        margin: [40, 0, 0, 5],
                                                        canvas: [
                                                            {
    
                                                                type: 'line',
                                                                x1: 0, y1: 0,
                                                                x2: 515, y2: 0,
                                                                lineWidth: 3,
                                                                lineColor: 'red'
                                                            },
    
                                                        ]
                                                    },
                                                    {
                                                        margin: [40, 0, 0, 0],
                                                        lineHeight: 2.5,
                                                        text: "PERIODO ENTRE " + fechasPeriodo,
                                                        style: 'redText',
                                                    },
                                                    {
                                                        margin: [40, 0, 0, 0],
                                                        lineHeight: 2,
                                                        text: "OBLIGACION HIPOTECARIA NUMERO:",
                                                        style: 'textBold',
                                                    },
                                                    {
                                                        absolutePosition: { y: 124, x: 405 },
                                                        canvas: [
                                                            {
                                                                type: 'rect',
                                                                x: 0,
                                                                y: 0,
                                                                w: 150,
                                                                h: 30,
                                                                color: '#DCDDDF',
    
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        lineHeight: 2,
                                                        text: auxThis.state.id,
                                                        style: 'textBold',
                                                        alignment: 'right ',
                                                        absolutePosition: { y: 134, x: 460 }
                                                    },
                                                    {
                                                        margin: [40, 0, 0, 5],
                                                        canvas: [
                                                            {
    
                                                                type: 'line',
                                                                x1: 0, y1: 0,
                                                                x2: 515, y2: 0,
                                                                lineWidth: 3,
                                                                lineColor: 'red'
                                                            },
    
                                                        ]
                                                    },
                                                    {
                                                        margin: [40, 10, 40, 0],
    
                                                        table: {
                                                            body: [[{
                                                                fillColor: '#DCDDDF',
                                                                border: [false, false, false, false],
                                                                columns: [
                                                                    {
                                                                        width: 250,
                                                                        stack: [
                                                                            {
                                                                                lineHeight: 1.5,
                                                                                text: "APRECIADO CLIENTE",
                                                                                style: 'text'
                                                                            },
                                                                            {
                                                                                lineHeight: 1.5,
                                                                                text: auxThis.state.clientesData[0].nombre,
                                                                                style: 'textBold'
                                                                            },
                                                                            {
                                                                                lineHeight: 1.5,
                                                                                text: auxThis.state.clientesData[0].direccion,
                                                                                style: 'text'
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        width: 'auto',
                                                                        columns: [
                                                                            {
                                                                                stack: [
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: "Valor del crédito:",
                                                                                        style: 'textBold'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: "Fecha de desembolso:",
                                                                                        style: 'textBold'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: "Tasa de interés:",
                                                                                        style: 'textBold'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: "Plazo:",
                                                                                        style: 'textBold'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: "Sistema de Amortización:",
                                                                                        style: 'textBold'
                                                                                    },
    
                                                                                ]
                                                                            },
                                                                            {
                                                                                stack: [
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: "$" + auxThis.state.vlrInicial,
                                                                                        style: 'text'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: fechaDesembolsoReal,
                                                                                        style: 'text'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: auxThis.state.interes + " % " + "E.A.",
                                                                                        style: 'text'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: auxThis.state.plazo + " meses",
                                                                                        style: 'text'
                                                                                    },
                                                                                    {
                                                                                        lineHeight: 1,
                                                                                        text: auxThis.state.amortizacion + " en " + auxThis.state.moneda,
                                                                                        style: 'text'
                                                                                    },
    
                                                                                ]
                                                                            }
                                                                        ],
                                                                        columnGap: 10
                                                                    },
    
                                                                ],
                                                                columnGap: 30
                                                            }]]
                                                        },
                                                    },
                                                    {
                                                        margin: [40, 10, 40, 0],
                                                        table: {
                                                            widths: ['*'],
                                                            body: [[{
                                                                fontSize: 10,
                                                                fillColor: '#DCDDDF',
                                                                border: [false, false, false, false],
                                                                alignment: 'center',
                                                                margin: [0, 7, 0, 7],
                                                                columns: [
                                                                    {
                                                                        text: 'PAGOS RECIBIDOS EN ESTE PERIODO',
                                                                        bold: true
                                                                    },
                                                                ]
                                                            }]]
                                                        }
                                                    },
                                                ]
                                            },
                                            footer: function () {
                                                return [
                                                    {
                                                        absolutePosition: { y: 60, x: 0 },
                                                        canvas: [
                                                            {
                                                                type: 'rect',
                                                                x: 0,
                                                                y: 0,
                                                                w: 700,
                                                                h: 78,
                                                                color: '#DCDDDF',
    
                                                            },
                                                        ]
    
                                                    },
                                                    {
                                                        lineHeight: 2,
                                                        text: "FORMA DE PAGO",
                                                        style: 'textBold',
                                                        alignment: 'center',
                                                        absolutePosition: { x: 0, y: 72 },
    
                                                    },
                                                    {
                                                        absolutePosition: { x: 20, y: 93 },
                                                        margin: [40, 0, 0, 40],
                                                        canvas: [
                                                            {
    
                                                                type: 'line',
                                                                x1: 0, y1: 0,
                                                                x2: 515, y2: 0,
                                                                lineWidth: 3,
                                                                lineColor: 'red'
                                                            },
    
                                                        ]
                                                    },
                                                    {
                                                        absolutePosition: { y: 102, x: 0 },
                                                        lineHeight: 1.2,
                                                        text: "Su pago lo puede efectuar en el Banco Caja Social Cuenta convenio No. 11542 \na nombre de Inmobiliaria Mobilia S.A. En cualquier ciudad del país",
                                                        style: 'textBold',
                                                        alignment: 'center'
                                                    },
                                                    {
                                                        width: 600,
                                                        image: footer,
                                                        alignment: 'center',
                                                        absolutePosition: { y: 137, x: 0 }
                                                    },
    
                                                ]
                                            },
                                            content: [
                                                liqTables,
                                                {
                                                    margin: [0, 10, 0, 0],
                                                    text: "Resultado del periodo:",
                                                    style: 'text'
                                                },
                                                auxTableRes,
                                                //clientes,
                                                {
                                                    margin: [0, 15, 0, 0],
                                                    table: {
                                                        widths: ['*'],
                                                        body: [[{
                                                            fontSize: 10,
                                                            fillColor: '#DCDDDF',
                                                            border: [false, false, false, false],
                                                            alignment: 'center',
                                                            margin: [0, 7, 0, 5],
                                                            columns: [
                                                                {
                                                                    lineHeight: 1.5,
                                                                    text: textoMora,
                                                                    bold: false
                                                                }
                                                            ]
                                                        }]]
                                                    }
                                                },
    
    
                                            ],
                                            styles: {
                                                subtitle: {
                                                    fontSize: 10,
                                                    bold: true
                                                },
                                                text: {
                                                    fontSize: 10,
                                                    bold: false
                                                },
                                                textBoldTable: {
                                                    fontSize: 9,
                                                    bold: true
                                                },
                                                headerPago: {
                                                    fontSize: 10,
                                                    bold: false
                                                },
                                                textBold: {
                                                    fontSize: 10,
                                                    bold: true
                                                },
                                                tableHeader: {
                                                    fontSize: 8,
                                                    bold: true,
                                                },
                                                tableHeaderColor: {
                                                    fontSize: 8,
                                                    bold: true,
                                                    color: 'white',
                                                    fillColor: '#e92128',
                                                },
                                                redText: {
                                                    fontSize: 10,
                                                    bold: true,
                                                    color: '#e92128',
                                                },
                                                tableExample: {
                                                    fontSize: 8,
                                                    color: 'black'
    
                                                },
                                                tableExample2: {
                                                    fontSize: 9,
                                                    color: 'black',
                                                },
    
                                                title1: {
                                                    fontSize: 15,
                                                    color: 'white'
                                                },
                                                title2: {
                                                    fontSize: 20,
                                                    bold: true,
                                                    color: 'white'
                                                }
    
                                            }
                                        };
                                        Swal.close();
                                        pdfMake.createPdf(docDefinition).download('prueba.pdf');
                                        if (window.sessionStorage.getItem("initialPDF") == "true") {
                                            window.sessionStorage.setItem("initialPDF", "false")
                                            this.delPago();
                                        }
                                    } else {
                                        document.getElementById("warnalert").innerHTML = "No existen valores UVR para el periodo seleccionado";
                                        $('#warnalert').show();
                                        $("#warnalert").fadeOut(7000);
                                    }
                                })
                        })
                })
        } else {
            Swal.close();
            Swal.fire({
                title: 'Error',
                text: 'Debe seleccionar un crédito',
                type: 'error',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    generatePDF = e => {
        e.preventDefault();
        Swal.fire({ title: "Cargando", allowOutsideClick: false });
        Swal.showLoading();
        let actUsrToken = window.sessionStorage.getItem('localToken');
        let neededPaymentsPesos = []
        let neededPaymentsUVR = []

        axios
            .get(apiMethods.GET_CREDITO_ALL, {
                headers: {
                    Authorization: actUsrToken
                }
            })
            .then(response => {
                response.data.credits.forEach(credit => {
                    if (credit.transacciones) {


                        credit.transacciones.forEach(pago => {

                            let fechaPagoStr = new Date(pago.fechaTx).toISOString()
                            if (fechaPagoStr.charAt(12) != 5 || fechaPagoStr.charAt(11) != 0) {
                                fechaPagoStr = this.setCharAt(fechaPagoStr, 12, "5")
                                fechaPagoStr = this.setCharAt(fechaPagoStr, 11, "0")
                            }
                            let fechaPago = new Date(fechaPagoStr)

                            let startDate = this.state.startDate
                            startDate.setHours(0, 0, 0, 0);

                            let endDate = this.state.endDate
                            endDate.setHours(0, 0, 0, 0);

                            if (fechaPago >= startDate && fechaPago <= endDate) {
                                if (pago.vlrTx != 0) {
                                    pago.creditoData = credit
                                    if (pago.creditoData.moneda == "uvr") {
                                        neededPaymentsUVR.push(pago);
                                    } else {
                                        neededPaymentsPesos.push(pago)
                                    }
                                }
                            }
                        })
                    }
                })

                let tablePesos = [];
                let tableUVR = [];
                let clients = [];


                neededPaymentsPesos.forEach(pago => {
                    let auxPago = [pago.creditoData.financialCode ? pago.creditoData.financialCode : pago.creditoData.id, ("0" + this.fixTimeZone(new Date(pago.fechaTx)).getDate()).slice(-2) + "-" + ("0" + (this.fixTimeZone(new Date(pago.fechaTx)).getMonth() + 1)).slice(-2) + "-" + this.fixTimeZone(new Date(pago.fechaTx)).getFullYear(), "$" + this.state.formatter.format(this.toFixed(pago.vlrTx, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoCapital, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoIntCte, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoIntMora, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoSegVida, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoSegTodoRiesgo, 0))]

                    if ((pago.vlrTx > (pago.vlrAplicadoCapital + pago.vlrAplicadoIntCte + pago.vlrAplicadoIntMora + pago.vlrAplicadoSegVida + pago.vlrAplicadoSegTodoRiesgo)) && (pago.creditoData.liquidaciones[pago.id + 1].capital == 0 && pago.creditoData.liquidaciones[pago.id + 1].interesCorriente == 0 && pago.creditoData.liquidaciones[pago.id + 1].interesMoratorio == 0 && pago.creditoData.liquidaciones[pago.id + 1].segVida == 0 && pago.creditoData.liquidaciones[pago.id + 1].segTodoRiesgo == 0)) {
                        pago.sobrante = pago.vlrTx - (pago.vlrAplicadoCapital + pago.vlrAplicadoIntCte + pago.vlrAplicadoIntMora + pago.vlrAplicadoSegVida + pago.vlrAplicadoSegTodoRiesgo)
                        auxPago.push(this.state.formatter.format(this.toFixed(pago.vlrTx - (pago.vlrAplicadoCapital + pago.vlrAplicadoIntCte + pago.vlrAplicadoIntMora + pago.vlrAplicadoSegVida + pago.vlrAplicadoSegTodoRiesgo), 0)))
                    } else {
                        auxPago.push("-")
                    }
                    clients.push(pago.creditoData.deudores["0"])
                    tablePesos.push(auxPago);
                })
                clients.push("-")
                neededPaymentsUVR.forEach(pago => {
                    let auxFechaPago = new Date(pago.fechaTx).toISOString()
                    if (auxFechaPago.charAt(12) != 0 || auxFechaPago.charAt(11) != 5) {
                        auxFechaPago = this.setCharAt(auxFechaPago, 12, "5")
                        auxFechaPago = this.setCharAt(auxFechaPago, 11, "0")
                    }
                    pago.fechaTx = auxFechaPago
                    let auxPago = [pago.creditoData.financialCode ? pago.creditoData.financialCode : pago.creditoData.id, ("0" + this.fixTimeZone(new Date(pago.fechaTx)).getDate()).slice(-2) + "-" + ("0" + (this.fixTimeZone(new Date(pago.fechaTx)).getMonth() + 1)).slice(-2) + "-" + new Date(pago.fechaTx).getFullYear(), "$" + this.state.formatter.format(this.toFixed(pago.vlrTx, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoCapital, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoIntCte, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoIntMora, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoSegVida, 0)), "$" + this.state.formatter.format(this.toFixed(pago.vlrAplicadoSegTodoRiesgo, 0))]
                    if ((pago.vlrTx > (pago.vlrAplicadoCapital + pago.vlrAplicadoIntCte + pago.vlrAplicadoIntMora + pago.vlrAplicadoSegVida + pago.vlrAplicadoSegTodoRiesgo)) && (pago.creditoData.liquidaciones[pago.id + 1].capital == 0 && pago.creditoData.liquidaciones[pago.id + 1].interesCorriente == 0 && pago.creditoData.liquidaciones[pago.id + 1].interesMoratorio == 0 && pago.creditoData.liquidaciones[pago.id + 1].segVida == 0 && pago.creditoData.liquidaciones[pago.id + 1].segTodoRiesgo == 0)) {
                        pago.sobrante = pago.vlrTx - (pago.vlrAplicadoCapital + pago.vlrAplicadoIntCte + pago.vlrAplicadoIntMora + pago.vlrAplicadoSegVida + pago.vlrAplicadoSegTodoRiesgo)
                        auxPago.push(this.state.formatter.format(this.toFixed(pago.vlrTx - (pago.vlrAplicadoCapital + pago.vlrAplicadoIntCte + pago.vlrAplicadoIntMora + pago.vlrAplicadoSegVida + pago.vlrAplicadoSegTodoRiesgo), 0)))
                    } else {
                        auxPago.push("-")
                    }
                    clients.push(pago.creditoData.deudores["0"])
                    tableUVR.push(auxPago);
                })

                let division = clients.indexOf("-")
                let uvr = false;
                let cntPesos = 0;
                let cntUVR = 0;

                Promise.all(clients.map((id) => {
                    if (id != "-") {
                        var demo = axios
                            .get(apiMethods.GET_CLIENTE_DOCUMENTO + id, {
                                headers: {
                                    Authorization: actUsrToken
                                }
                            })
                            .then(response => {
                                return response.data.client.nombre;
                            })
                        return demo;
                    }
                })).then(element => {
                    element[division] = "-"
                    element.forEach(name => {
                        if (name != "-") {
                            if (uvr == false) {
                                tablePesos[cntPesos].splice(1, 0, name);
                                cntPesos++;
                            } else {
                                tableUVR[cntUVR].splice(1, 0, name);
                                cntUVR++;
                            }
                        } else {
                            uvr = true;
                        }
                    })

                    tablePesos.splice(0, 0, [{ bold: true, text: "CÓDIGO" }, { bold: true, text: "CLIENTE" }, {
                        bold: true,
                        text: "FECHA ABONO"
                    }, { bold: true, text: "VALOR ABONO" }, { bold: true, text: "ABONO A CAPITAL" }, {
                        bold: true,
                        text: "INTS. CORRIENTES"
                    }, { bold: true, text: "INTS. DE MORA" }, { bold: true, text: "SEGURO VIDA" }, {
                        bold: true,
                        text: "SEGURO TODO RIESGO"
                    }, { bold: true, text: "SOBRANTE" }])
                    tablePesos.splice(1, 0, [{ bold: true, text: "PESOS" }, "", "", "", "", "", "", "", "", ""])
                    tableUVR.splice(0, 0, [{ bold: true, text: "CÓDIGO" }, { bold: true, text: "CLIENTE" }, {
                        bold: true,
                        text: "FECHA ABONO"
                    }, { bold: true, text: "VALOR ABONO" }, { bold: true, text: "ABONO A CAPITAL" }, {
                        bold: true,
                        text: "INTS. CORRIENTES"
                    }, { bold: true, text: "INTS. DE MORA" }, { bold: true, text: "SEGURO VIDA" }, {
                        bold: true,
                        text: "SEGURO TODO RIESGO"
                    }, { bold: true, text: "SOBRANTE" }])
                    tableUVR.splice(1, 0, [{ bold: true, text: "UVR" }, "", "", "", "", "", "", "", "", ""])

                    let finalPesos = []
                    let finalTxPesos = 0
                    let finalCapitalPesos = 0
                    let finalIntCtePesos = 0
                    let finalIntMoraPesos = 0
                    let finalSegVidaPesos = 0
                    let finalSegTodoRiesgoPesos = 0
                    let finalSobrantePesos = 0
                    let finalCapitalUVR = 0
                    let finalIntCteUVR = 0
                    let finalIntMoraUVR = 0
                    let finalSegVidaUVR = 0
                    let finalSegTodoRiesgoUVR = 0
                    let finalSobranteUVR = 0
                    let finalUVR = []
                    let finalTxUVR = 0

                    neededPaymentsPesos.forEach(payment => {
                        finalTxPesos += payment.vlrTx
                        finalCapitalPesos += payment.vlrAplicadoCapital
                        finalIntCtePesos += payment.vlrAplicadoIntCte
                        finalIntMoraPesos += payment.vlrAplicadoIntMora
                        finalSegVidaPesos += payment.vlrAplicadoSegVida
                        finalSegTodoRiesgoPesos += payment.vlrAplicadoSegTodoRiesgo
                        if (payment.sobrante) {
                            finalSobrantePesos += payment.sobrante
                        }
                    })

                    if (finalSobrantePesos === 0) {
                        finalSobrantePesos = "-"
                    }

                    finalPesos.push("")
                    finalPesos.push("")
                    finalPesos.push({ bold: true, text: "SUB-TOTAL" })

                    finalPesos.push(finalTxPesos)
                    finalPesos.push(finalCapitalPesos)
                    finalPesos.push(finalIntCtePesos)
                    finalPesos.push(finalIntMoraPesos)
                    finalPesos.push(finalSegVidaPesos)
                    finalPesos.push(finalSegTodoRiesgoPesos)
                    finalPesos.push(finalSobrantePesos)


                    neededPaymentsUVR.forEach(payment => {
                        finalTxUVR += payment.vlrTx
                        finalCapitalUVR += payment.vlrAplicadoCapital
                        finalIntCteUVR += payment.vlrAplicadoIntCte
                        finalIntMoraUVR += payment.vlrAplicadoIntMora
                        finalSegVidaUVR += payment.vlrAplicadoSegVida
                        finalSegTodoRiesgoUVR += payment.vlrAplicadoSegTodoRiesgo
                        if (payment.sobrante) {
                            finalSobranteUVR += payment.sobrante
                        }
                    })


                    if (finalSobranteUVR === 0) {
                        finalSobranteUVR = "-"
                    }

                    finalUVR.push("")
                    finalUVR.push("")
                    finalUVR.push("SUB-TOTAL")

                    finalUVR.push(finalTxUVR)
                    finalUVR.push(finalCapitalUVR)
                    finalUVR.push(finalIntCteUVR)
                    finalUVR.push(finalIntMoraUVR)
                    finalUVR.push(finalSegVidaUVR)
                    finalUVR.push(finalSegTodoRiesgoUVR)
                    finalUVR.push(finalSobranteUVR)

                    let resultTable = []

                    resultTable.push([{ bold: true, text: "CÓDIGO" }, { bold: true, text: "CLIENTE" }, {
                        bold: true,
                        text: "FECHA ABONO"
                    }, { bold: true, text: "VALOR ABONO" }, { bold: true, text: "ABONO A CAPITAL" }, {
                        bold: true,
                        text: "INTS. CORRIENTES"
                    }, { bold: true, text: "INTS. DE MORA" }, { bold: true, text: "SEGURO VIDA" }, {
                        bold: true,
                        text: "SEGURO TODO RIESGO"
                    }, { bold: true, text: "SOBRANTE" }])

                    let resultTableNumbers = ["", "", { bold: true, text: "TOTAL" }, {
                        bold: true,
                        text: this.state.formatter.format(this.toFixed(finalPesos[3] + finalUVR[3], 0))
                    }, {
                            bold: true,
                            text: this.state.formatter.format(this.toFixed(finalPesos[4] + finalUVR[4], 0))
                        }, {
                            bold: true,
                            text: this.state.formatter.format(this.toFixed(finalPesos[5] + finalUVR[5], 0))
                        }, {
                            bold: true,
                            text: this.state.formatter.format(this.toFixed(finalPesos[6] + finalUVR[6], 0))
                        }, {
                            bold: true,
                            text: this.state.formatter.format(this.toFixed(finalPesos[7] + finalUVR[7], 0))
                        }, { bold: true, text: this.state.formatter.format(this.toFixed(finalPesos[8] + finalUVR[8], 0)) }]

                    resultTable.push(resultTableNumbers)

                    let auxSobrantePesos = 0
                    let auxSobranteUVR = 0

                    if (finalPesos[9] == "-") {
                        auxSobrantePesos = 0
                    } else {
                        auxSobrantePesos = finalUVR[9]
                    }

                    if (finalUVR[9] == "-") {
                        auxSobranteUVR = 0
                    } else {
                        auxSobranteUVR = finalUVR[9]
                    }

                    resultTableNumbers.push({
                        bold: true,
                        text: this.state.formatter.format(this.toFixed(auxSobrantePesos + auxSobranteUVR, 0))
                    })

                    for (let i = 3; i < finalPesos.length; i++) {
                        if (i != finalPesos.length - 1) {
                            finalPesos[i] = this.state.formatter.format(this.toFixed(finalPesos[i], 0))
                        } else {
                            if (finalPesos[i] != "-") {
                                finalPesos[i] = this.state.formatter.format(this.toFixed(finalPesos[i], 0))
                            }
                        }
                    }

                    for (let i = 3; i < finalUVR.length; i++) {
                        if (i != finalUVR.length - 1) {
                            finalUVR[i] = this.state.formatter.format(this.toFixed(finalUVR[i], 0))
                        } else {
                            if (finalUVR[i] != "-") {
                                finalUVR[i] = this.state.formatter.format(this.toFixed(finalUVR[i], 0))
                            }
                        }
                    }

                    for (let i = 0; i < finalPesos.length; i++) {
                        finalPesos[i] = { bold: true, text: finalPesos[i] }
                    }

                    for (let i = 0; i < finalUVR.length; i++) {
                        finalUVR[i] = { bold: true, text: finalUVR[i] }
                    }


                    tablePesos.push(finalPesos)
                    tableUVR.push(finalUVR)

                    var title = "AUXILIAR APLICACIÓN DE PAGOS PARA CONTABILIZAR DESDE " + ("0" + this.state.startDate.getDate()).slice(-2) + "/" + ("0" + (this.state.startDate.getMonth() + 1)).slice(-2) + "/" + this.state.startDate.getFullYear() + " HASTA " + ("0" + this.state.endDate.getDate()).slice(-2) + "/" + ("0" + (this.state.endDate.getMonth() + 1)).slice(-2) + "/" + this.state.endDate.getFullYear()
                    var docDefinition = {
                        pageMargins: [40, 80, 40, 200],
                        header: function () {
                            return [
                                {
                                    margin: [0, 0, 0, 10],
                                    canvas: [
                                        {
                                            type: 'rect',
                                            x: -15,
                                            y: -15,
                                            w: 610,
                                            h: 76,
                                            color: '#e92128'
                                        },
                                    ]
                                },
                                {
                                    margin: [0, 0, 0, 10],
                                    canvas: [
                                        {
                                            type: 'rect',
                                            x: -15,
                                            y: -20,
                                            w: 610,
                                            h: 8,
                                            color: '#B9B9B9'
                                        },
                                    ]
                                },
                                {
                                    text: "INMOBILIARIA",
                                    style: 'title1',
                                    absolutePosition: { x: 305, y: 5 }
                                },
                                {
                                    text: "MOBILIA",
                                    style: 'title2',
                                    absolutePosition: { x: 305, y: 27 }
                                },
                                {
                                    width: 100,
                                    image: logo,
                                    alignment: 'center',
                                    absolutePosition: { y: 6, x: -110 }
                                },
                            ]
                        },
                        footer: function () {
                            return [
                                {
                                    width: 600,
                                    image: footer,
                                    alignment: 'center',
                                    absolutePosition: { y: 137, x: 0 }
                                },

                            ]
                        },
                        content: [
                            {
                                bold: true,
                                text: title,
                                alignment: 'center',
                                colSpan: 2,
                                border: [false, false, false, false]
                            },
                            {
                                margin: [0, 15, 0, 0],
                                style: 'tableExample',
                                table: {
                                    body: tableUVR
                                }
                            },
                            {
                                margin: [0, 30, 0, 0],
                                style: 'tableExample',
                                table: {
                                    body: tablePesos
                                }
                            },
                            {
                                margin: [0, 30, 0, 0],
                                style: 'tableExample',
                                table: {
                                    body: resultTable
                                }
                            },

                        ],
                        styles: {
                            subtitle: {
                                fontSize: 10,
                                bold: true
                            },
                            text: {
                                fontSize: 10,
                                bold: false
                            },
                            textBoldTable: {
                                fontSize: 9,
                                bold: true
                            },
                            headerPago: {
                                fontSize: 10,
                                bold: false
                            },
                            textBold: {
                                fontSize: 10,
                                bold: true
                            },
                            tableHeader: {
                                fontSize: 8,
                                bold: true,
                            },
                            tableHeaderColor: {
                                fontSize: 8,
                                bold: true,
                                color: 'white',
                                fillColor: '#e92128',
                            },
                            redText: {
                                fontSize: 10,
                                bold: true,
                                color: '#e92128',
                            },
                            tableExample: {
                                fontSize: 8,
                                color: 'black'

                            },
                            tableExample2: {
                                fontSize: 9,
                                color: 'black',
                            },

                            title1: {
                                fontSize: 15,
                                color: 'white'
                            },
                            title2: {
                                fontSize: 20,
                                bold: true,
                                color: 'white'
                            }
                        }
                    };
                    this.setState({ startDate: new Date(), endDate: new Date(), startDateSelected: false });
                    Swal.close();
                    pdfMake.createPdf(docDefinition).download("AUXILIAR PAGOS.pdf");

                })
            })
    }

    handleChangeDatePicker(date, dateTarget) {
        if (dateTarget === "start") {
            this.setState({
                startDate: date,
                startDateSelected: true
            });
        } else {
            this.setState({
                endDate: date,
            });
        }
    };

    render = () => {
        const { documento, actualListDocumento, actualListNombre, estado } = this.state
        let actUsrToken = window.sessionStorage.getItem('localToken');
        if (actUsrToken != undefined) {
            let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
            if (decodedPayload.roleId == 1 || decodedPayload.roleId == 5) {
                const DatePickerState = ({ value, onClick }) => (
                    <Button className="react-datepicker-ignore-onclickoutside" onClick={onClick}
                        id="datePicker">{value}</Button>
                );
                return (
                    <React.Fragment>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
                            <Sidebar />
                            <div className="card" style={{width: "100%"}}>
                                <p className="alert alert-success float-right" id="alertCli"></p>
                                <p className="alert alert-danger float-right" id="warnalert"></p>
                                <div
                                    style={{
                                        fontSize: "15pt",
                                        fontWeight: "500",
                                        marginBottom: "2rem",
                                        marginTop: "1rem",
                                        marginLeft: "0rem",
                                    }}
                                >
                                    Generación de documentos
                                </div>
                                <div>
                                    <Button variant="contained" color="red"
                                        onClick={(e) => this.handleToggle(e, "periodicReportDialog", "paymentsReport")}>Descargar informe pagos</Button>
                                    <br></br>
                                    <br></br>
                                    <Button variant="contained" color="red"
                                        name="naturalBtn" onClick={(e) => this.handleToggle(e, "massiveLiquidationDialog")}>Liquidar todos los créditos y descargar extractos</Button>
                                    <br></br>
                                    <br></br>
                                    <Button variant="contained" color="red"
                                        name="naturalBtn" onClick={(e) => this.handleToggle(e, "periodicReportDialog", "managementReport")}>Descargar informe gerencial</Button>
                                </div>
                                <div>
                                    <Dialog
                                        open={this.state.periodicReportDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        disableEnforceFocus
                                    >
                                        <DialogTitle>
                                            {this.state.reportType == "paymentsReport" ? "Descargar informe pagos" : "Generar informe gerencial"}
                                        </DialogTitle>
                                        <DialogContent>
                                            {!this.state.excelCreated && <>
                                                <DialogContentText>Por favor indique el periodo a descargar</DialogContentText>
                                                <Stack style={{ "marginTop": "1rem" }} spacing={3}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        label="Fecha inicio"
                                                        format="dd/MM/yyyy"
                                                        autoOk
                                                        value={this.state.startDate}
                                                        onChange={date => this.handleChangeDatePicker(date, "start")}
                                                        maxDate={this.state.endDate}
                                                    />
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        label="Fecha fin"
                                                        autoOk
                                                        format="dd/MM/yyyy"
                                                        value={this.state.endDate}
                                                        onChange={date => this.handleChangeDatePicker(date, "end")}
                                                        minDate={this.state.startDateSelected ? this.fixTimeZone(new Date(this.state.startDate)) : new Date()}
                                                        disableFuture
                                                    />
                                                </Stack>
                                            </>
                                            }
                                        </DialogContent>
                                        <DialogActions>
                                            {
                                                !this.state.excelCreated ? <Button variant="text" color="red"
                                                    onClick={this.state.reportType == "paymentsReport" ? this.generatePDF : this.downloadManagementReport}>Descargar</Button> :
                                                    <ExcelFile filename="Informe gerencial" element={<Button onClick={this.showData} variant="text" color="red" >Descargar</Button>}>
                                                        <ExcelSheet data={this.state.informeGerencialData.filter(element => (element.saldoCapital.replace(/,/g,'') >= 0))} name="Creditos">
                                                            <ExcelColumn label="Numero Credito" value="numeroCredito" />
                                                            <ExcelColumn label="No Identif cliente" value="documentoCliente" />
                                                            <ExcelColumn label="Nombres" value="nombreCliente" />
                                                            <ExcelColumn label="Inmueble" value="inmueble" />
                                                            <ExcelColumn label="Valor Crédito" value="valorCredito" />
                                                            <ExcelColumn label="Tipo Crédito" value="tipo" />
                                                            <ExcelColumn label="Fecha desembolso" value="fechaDesembolso" />
                                                            <ExcelColumn label="Plazo" value="plazo" />
                                                            <ExcelColumn label="Tasa Int Anual" value="interesAnual" />
                                                            <ExcelColumn label="Tasa Int Mensual" value="interesMensual" />
                                                            <ExcelColumn label="Sis Amortiza." value="amortizacion" />
                                                            <ExcelColumn label="Vlr Cuota" value="valorCuota" />
                                                            <ExcelColumn label="Saldo Total" value="saldoTotal" />
                                                            <ExcelColumn label="No Ctas Mora" value="cntCuotasMora" />
                                                            <ExcelColumn label="Vlr saldo mora" value="saldoMora" />
                                                            <ExcelColumn label="Saldo a Capital" value="saldoCapital" />
                                                            <ExcelColumn label="Saldo Int Cte" value="saldoIntCte" />
                                                            <ExcelColumn label="Saldo Int Mora" value="saldoIntMora" />
                                                            <ExcelColumn label="Saldo Seguros" value="saldoSeguros" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                            }
                                            <Button variant="text" color="red"
                                                onClick={(e) => this.handleToggle(e, "periodicReportDialog")}>Cerrar</Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.massiveLiquidationDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {!this.state.liquidated ? "Confirmar liquidación" : "Créditos liquidados"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                {!this.state.liquidated ? "¿Está seguro que desea liquidar todos los créditos disponibles?" : "Descargar estados de cuenta"}
                                            </DialogContentText>
                                            {
                                                this.state.liquidated && <Select
                                                    value={this.state.selectedCredit}
                                                    onChange={(e) => this.setState({ selectedCredit: e.target.value })}>
                                                    {
                                                        this.state.payments.map((payment, index) => {
                                                            return <MenuItem key={payment.credito} value={payment.credito}>{payment.credito}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            }
                                        </DialogContent>
                                        <DialogActions>
                                            {
                                                !this.state.liquidated ? <>
                                                    <Button variant="text" onClick={this.liquidateCredits} color="red">Si</Button>
                                                    <Button variant="text" onClick={((e) => this.handleToggle(e, "massiveLiquidationDialog"))} color="red">No</Button> </>
                                                    : <> <Button variant="text" color="red"
                                                        onClick={this.generatePDFCredit}>Descargar</Button>
                                                        <Button variant="text" color="red"
                                                            onClick={(e) => this.handleToggle(e, "massiveLiquidationDialog")}>Cerrar</Button> </>
                                            }
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </div>
                            </div>
                        </MuiPickersUtilsProvider>

                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <p>Acceso denegado</p>
                    </React.Fragment>
                )
            }
        } else {
            return (
                <React.Fragment>
                    <p>Acceso denegado</p>
                </React.Fragment>
            )
        }
    }
}

export default Documents;