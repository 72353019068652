import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import { Form, Col, Row } from "react-bootstrap";
import * as apiMethods from "../res/apiMethods.js";
import FileSaver from "file-saver";
import readXlsxFile from "read-excel-file";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";

import Sidebar from "../components/Sidebar";

class uvr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: "",
      file: "",
      cntCargasUVR: "",
      uvrLoads: [],
      offset: 0,
    };
  }

  componentDidMount() {
    window.sessionStorage.setItem("first", true);
    document.title = "UVR";
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;

    axios
      .get(apiMethods.GET_USUARIO_ID + localId, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var usr = response.data.users.username;
      })
      .catch((error) => {
        console.log(error);
      });

    var aux = this;

    var success = window.sessionStorage.getItem("success");
    if (success == "true") {
      document.getElementById("alertCli").innerHTML = "Carga exitosa";
      $("#alertCli").show();
      $("#alertCli").fadeOut(5000);
      window.sessionStorage.setItem("success", "false");
    } else {
      $("#alertCli").hide();
      $("#warnalert").hide();
    }

    $(document).ready(function () {
      $('input[type="file"]').change(function (e) {
        var fileName = e.target.files[0].name;
        $("#filename").text(fileName);
        aux.setState({ file: e.target.files[0] });
      });
    });

    axios
      .get(apiMethods.GET_AUXILIAR_ID, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        this.setState({ cntCargasUVR: response.data.auxiliar.cntCargasUVR });
        axios
          .get(
            apiMethods.GET_UVRLOAD_ID + response.data.auxiliar.cntCargasUVR,
            {
              headers: {
                Authorization: actUsrToken,
              },
            }
          )
          .then(async (response) => {
            document.getElementById("lastUpload").innerHTML =
              "Fecha UVR más reciente: " + response.data.uvrLoad.fecha;
            let uvrLoadsRes = await axios.get(
              apiMethods.GET_UVR_ALL + this.state.offset
            );
            this.setState({
              uvrLoads: uvrLoadsRes.data.uvrs,
              count: uvrLoadsRes.data.count,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate = () => {};

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  load = (e) => {
    e.preventDefault();
    var auxThis = this;
    let actUsrToken = window.sessionStorage.getItem("localToken");

    readXlsxFile(this.state.file).then((rows) => {
      for (let i = 0; i < rows.length; i++) {
        var auxFecha = new Date(rows[i][0]);
        var auxUVR = {
          fecha: auxFecha,
          valor: rows[i][1]
        };

        axios
          .post(apiMethods.PUT_UVR, auxUVR, {
            headers: {
              Authorization: actUsrToken,
            },
          })
          .then((response) => {
            if (i == rows.length - 1) {
              var demoAuxiliar = auxThis.state.cntCargasUVR;
              demoAuxiliar++;
              auxThis.setState({ cntCargasUVR: demoAuxiliar }, function () {
                axios
                  .patch(
                    apiMethods.PATCH_AUXILIAR_ID,
                    { cntCargasUVR: demoAuxiliar },
                    {
                      headers: {
                        Authorization: actUsrToken,
                      },
                    }
                  )
                  .then((response) => {
                    var auxFecha = new Date(rows[0][0]);
                    var fechaStr =
                      ("0" + auxFecha.getDate()).slice(-2) +
                      "-" +
                      ("0" + (auxFecha.getMonth() + 1)).slice(-2) +
                      "-" +
                      auxFecha.getFullYear();
                    var lastUVR = {
                      id: demoAuxiliar,
                      fecha: fechaStr,
                    };
                    axios
                      .post(apiMethods.CREATE_UVRLOAD, lastUVR, {
                        headers: {
                          Authorization: actUsrToken,
                        },
                      })
                      .then((response) => {
                        window.sessionStorage.setItem("success", "true");
                        window.location.reload();
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  download = (e) => {
    e.preventDefault();
    FileSaver.saveAs(
      process.env.PUBLIC_URL + "/EJEMPLO_CARGA_EXCEL.xlsx",
      "EJEMPLO_CARGA_EXCEL.xlsx"
    );
  };

  render = () => {
    const { documento, actualListDocumento, actualListNombre } = this.state;
    const columns = [
      {
        field: "fecha",
        headerName: "Fecha",
        type: "date",
        valueFormatter: (date) => {
          let d = new Date(date.row.fecha);
          const valueFormatted =
          ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
          d.getFullYear()
          return `${valueFormatted}`;
        },
        width: 200,
      },
      { field: "valor", headerName: "Valor", width: 200 },
    ];
    const setPage = async (page) => {
      Swal.fire({ title: "Cargando", allowOutsideClick: false });
      Swal.showLoading();
      let offset = this.state.offset;
      offset = 15 * page;
      let uvrLoadsRes = await axios.get(apiMethods.GET_UVR_ALL + offset);
      let uvr = this.state.uvrLoads;
      uvr = [...uvr, ...uvrLoadsRes.data.uvrs];
      this.setState({ offset: offset, uvrLoads: uvr });
      Swal.close();
    }
    let actUsrToken = window.sessionStorage.getItem("localToken");
    if (actUsrToken != undefined) {
      let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
      if (decodedPayload.roleId == 1) {
        return (
          <React.Fragment>
            <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
              <Sidebar />
            <div className="card">
              <form>
                <p
                  className="alert alert-success float-right"
                  id="alertCli"
                ></p>
                <Form.Label className="formHeader">
                  Carga de archivo con tasas de conversión UVR
                </Form.Label>
                <br></br>
                <br></br>
                <p>
                  El archivo debe ser un documento tipo XLSX con dos columnas,
                  fecha y valor. Las columnas no deben tener titulos. El valor
                  debe usar "." para representar el punto decimal y debe estar
                  en tipo de dato general (sin formato especifico). La fecha
                  debe estar en tipo de dato fecha.
                </p>
                <br></br>
                <Row>
                  <Col md="6">
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFileLang"
                        lang="es"
                      ></input>
                      <label
                        class="custom-file-label"
                        id="filename"
                        for="customFileLang"
                      >
                        Seleccionar Archivo
                      </label>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <p id="lastUpload"></p>
                <br></br>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "10% 30%",
                    columnGap: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={this.load}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      component="span"
                      color="red"
                      variant="contained"
                    >
                      Subir
                    </Button>
                  </label>

                  <label htmlFor="demo">
                    <Button
                      component="span"
                      onClick={this.download}
                      color="red"
                      style={{ width: "100%" }}
                      variant="contained"
                      autoFocus
                    >
                      Descargar ejemplo de carga
                    </Button>
                  </label>
                </div>
              </form>
              {this.state.uvrLoads && (
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={this.state.uvrLoads}
                    columns={columns}
                    rowCount={this.state.count}
                    pageSize={15}
                    getRowId={(row) => row._id}
                    onPageChange={(newPage) => setPage(newPage)}
                  />
                </div>
              )}
            </div>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <p>Acceso denegado</p>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <p>Acceso denegado</p>
        </React.Fragment>
      );
    }
  };
}

export default uvr;
