import $ from 'jquery'
import * as apiMethods from '../res/apiMethods.js';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import { Form } from "react-bootstrap";
import { Col, Row, FormGroup } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";

import "date-fns";

import { useFormik } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import jwtDecode from "jwt-decode";
import history from "../services/history.service.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import eachDayOfInterval from "date-fns/eachDayOfInterval/index.js";
import CurrencyFormat from "react-currency-format";
import * as yup from "yup";
import Swal from "sweetalert2";

import Sidebar from "../components/Sidebar";

const Login = () => {

    const [props, setProps] = React.useState({
        loggedIn: null,
        username: '',
        password: '',
        password2: '',
    });

    useEffect(() => {
        (async () => {
            Swal.fire({ title: "Cargando..." });
            Swal.showLoading();
            let actUsrToken = window.sessionStorage.getItem('localToken');
            if (actUsrToken) {
                document.title = "Perfil";
                axios
                    .get(apiMethods.GET_USUARIO_ID + JSON.parse(atob(actUsrToken.split(".")[1])).usrId)
                    .then(response => {
                        var usr = response.data.users.username
                        setState({ username: usr, loggedIn: true });
                        Swal.close();
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                document.title = "Login";
                setState({ loggedIn: false });
                Swal.close();
            }
        })();
    }, []);

    const formik = useFormik({
        initialValues: props
    });

    const submitHandlerLogin = e => {
        e.preventDefault()
        Swal.fire({ title: "Cargando..." });
        Swal.showLoading();
        axios
            .post(apiMethods.LOGIN, formik.values)
            .then(response => {
                switch (response.data.code) {
                    case 0:
                        Swal.close();
                        window.sessionStorage.setItem('localToken', response.data.token);
                        window.sessionStorage.setItem('loggedIn', true);
                        window.sessionStorage.setItem('username', jwtDecode(response.data.token).username);
                        if (jwtDecode(response.data.token).roleId == 4) {
                            history.push("/clients/search/" + jwtDecode(response.data.token).username)
                        } else {
                            history.push("/home")
                        }
                        window.location.reload();
                        break;
                    case 401:
                        Swal.close();
                        toast.error("Error de autenticación");
                        break;
                    case 406:
                        Swal.close();
                        toast.error("Información insuficiente");
                        break;
                    default:
                        Swal.close();
                        break;
                }
            })
            .catch(error => {
                console.log(error)
            })

    }

    const setState = (value) => {
        setProps((prevState) => ({ ...prevState, ...value }));
    };

    const logout = () => {
        sessionStorage.clear();
        sessionStorage.setItem("loggedIn", false);
        setState({ loggedIn: false })
        history.push('/login');
        window.location.reload();
    }

    const updatePassword = async () => {
        if (formik.values.password == formik.values.password2) {
            let updateResponse = await axios.patch(apiMethods.PATCH_USUARIO_ID + JSON.parse(atob(window.sessionStorage.getItem('localToken').split(".")[1])).usrId, { password: formik.values.password })
            if (updateResponse.data.code == 0) {
                toast.success("Contraseña actualizada exitosamente");
            } else {
                toast.error("Error al actualizar contraseña");
            }
        } else {
            toast.error("Las contraseñas no coinciden");
        }
    }

    return (
        props.loggedIn != null && (
            props.loggedIn ? (
                <React.Fragment>
                          
                    <ToastContainer />
                    <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
                    <Sidebar /> 
                    <div className="card" style={{width: "100%"}}>
                        <div
                            style={{
                                fontSize: "15pt",
                                fontWeight: "500",
                                marginBottom: "1rem",
                                marginTop: "1rem",
                                marginLeft: "1rem",
                            }}
                        >
                            Detalle Usuario: {props.username}
                        </div>
                        <div
                            style={{
                                fontSize: "14pt",
                                fontWeight: "350",
                                marginBottom: "1rem",
                                marginTop: "0.5rem",
                                marginLeft: "1rem",
                            }}
                        >
                            Cambiar contraseña
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", marginBottom: "1.5rem" }}>
                            <div style={{width: "100%", textAlign: "center"}} className="create-credit-input-row">
                                <TextField
                                    className="create-credit-input"
                                    id="password"
                                    label="Contraseña"
                                    value={formik.values.password || ''}
                                    type="password"
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div style={{width: "100%", textAlign: "center"}} className="create-credit-input-row">
                                <TextField
                                    className="create-credit-input"
                                    id="password2"
                                    label="Confirmar contraseña"
                                    type="password"
                                    value={formik.values.password2 || ''}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <Button variant="contained" color="red" size="medium" onClick={updatePassword}>Cambiar</Button>
                            </div>
                        </div>
                        <div style={{ width: "100%", textAlign: "center", marginBottom: "1rem" }}>
                            

                        </div>
                    </div>
                    </div>

                </React.Fragment>
            ) : (
                <React.Fragment>
                    <ToastContainer />
                    <div className="center">
                        <div className="cardSquare">
                            <form>
                                <div className="loginHead">
                                    <img
                                        src={require('../res/logo-mobilia.jpg')}
                                        height="170"
                                        className="d-inline-block align-top"
                                        alt="React Bootstrap logo"
                                    />
                                </div>

                                <TextField
                                    style={{ marginBottom: "1rem", marginTop: "2rem", width: "100%" }}
                                    label="Usuario"
                                    id="username"
                                    value={formik.values.username || ''}
                                    onChange={formik.handleChange}
                                />
                                <TextField
                                    label="Contraseña"
                                    id="password"
                                    style={{ marginBottom: "2rem", width: "100%" }}
                                    type="password"
                                    value={formik.values.password || ''}
                                    onChange={formik.handleChange}
                                />
                                <div style={{ width: "100%", textAlign: "center" }}>
                                    <Button
                                        variant="contained"
                                        color="red"
                                        size="medium"
                                        onClick={submitHandlerLogin}
                                    >Ingresar</Button>

                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            )

        )
    )
}


export default Login;