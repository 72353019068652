import React from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import * as apiMethods from "../res/apiMethods.js";
import "date-fns";
import Button from "@material-ui/core/Button";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Swal from "sweetalert2";

import { Modal } from "react-bootstrap";
import Stack from '@mui/material/Stack';

import Sidebar from "../components/Sidebar";

const ClosedPeriods = () => {
  const [props, setProps] = React.useState({
    usersData: [],
    createDialog: false,
    update: false,
    userId: "",
    startDate: new Date(),
    endDate: new Date(),
    startDateSelected: false,
    showModalPeriod: false
  });

  const validationSchema = yup.object().shape({
    username: yup.string().required("El nombre de usuario es requerido"),
    contraseña: yup.string().required("La contraseña es requerida"),
    roleId: yup.string().required("El rol es requerido"),
  });

  const setState = (value) => {
    setProps((prevState) => ({ ...prevState, ...value }));
  };

  const handleClosePeriod = (e) => {
    setState({ showModalPeriod: false });
  };

  const handleChangeDatePickerPayment = (date, dateTarget) => {
    if (dateTarget === "start") {
        setState({
            startDate: date,
            startDateSelected: true
        });
    } else {
        setState({
            endDate: date,
        });
    }
  };

  const block = () => {
    let watever = window.sessionStorage.getItem("localToken")
    let watever2 = JSON.parse(atob(watever.split(".")[1]))
    console.log(watever2.roleId)
    return watever2.roleId == 1 ? false : true
  }

  const handleShowPeriod = (e) => {
    console.log(props.startDate)
    e.preventDefault();
    setState({ showModalPeriod: true });
  };

  const cerrar = (body) => {
/*
    Swal.fire({ title: "Realizando Cierre de periodo 2", allowOutsideClick: false });
    Swal.showLoading();

    axios
        .patch(apiMethods.CIERRE_PERIODO, body, {})
        .then((response) => {
          Swal.close();
          toast.success(`Cierre de periodo exitoso`);
          // window.location.reload();
          return true;
        })
        .catch((error) => {
          Swal.close();
          console.error(error);
          toast.error("Error al cerrar el periodo");
          return false;
        });*/
  }

  const closePeriod = () =>{

    let body = {
      //fechaIni: props.startDate.getFullYear() + "/" + ("0"+(props.startDate.getMonth()+1)).slice(-2) + "/" + ("0" + props.startDate.getDate()).slice(-2),
      fechaFin: props.endDate.getFullYear() + "/" + ("0"+(props.endDate.getMonth()+1)).slice(-2) + "/" + ("0" + props.endDate.getDate()).slice(-2)
      //fechaFin:"2000/02/31"
    };

    //console.log(body.fechaIni);
    console.log(body.fechaFin);

    

    //Swal.fire({ title: "Realizando Cierre de periodo", allowOutsideClick: false });
    //Swal.showLoading();

    axios
        .patch(apiMethods.CIERRE_PERIODO_FECHA, body, {})
        .then((response) => {
          //Swal.close();
          toast.success(`Fechas exitosas`);
          // window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error de fechas");
        });

    Swal.fire({ title: "Realizando Cierre de periodo", allowOutsideClick: false });
    Swal.showLoading();

    axios
        .patch(apiMethods.CIERRE_PERIODO, body, {})
        .then((response) => {
          Swal.close();
          toast.success(`Cierre de periodo exitoso`);
          setTimeout(() => {
            //window.location.reload();
          }, 5000);
          // window.location.reload();
          return true;
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error al cerrar el periodo");
          return false;
        });

        //if(cerrar(body)){Swal.close();}else{console.log("Socio no se pudo");toast.error("Nada no se pudo");};
        
  }

  /*<KeyboardDatePicker
                style={{"width":"60%"},{"marginLeft":"0.5rem"}}
                disableToolbar
                variant="inline"
                label="Fecha inicio"
                format="dd/MM/yyyy"
                disabled={true}
                autoOk
                value={props.startDate}
                onChange={date => handleChangeDatePickerPayment(date, "start")}
                maxDate={props.endDate}
            />*/

  return window.sessionStorage.getItem("localToken") ? (
    <React.Fragment>
      <ToastContainer />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
          <Sidebar />
        <div className="leftBtn">
          <Button
            color="red"
            variant="contained"
            onClick={handleShowPeriod}
            style={{ marginLeft: "36%" , marginTop: "1rem"}}
            disabled={block()}>
            Cierre de periodo
          </Button>
        </div>
        </div>

        <Modal
          centered={true}
          show={props.showModalPeriod}
          onHide={handleClosePeriod}>
          <Modal.Header closeButton>
            <Modal.Title>Cierre de periodos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Por favor indique el rango de periodos que se quieren cerrar
          </Modal.Body>
          <Stack style={{ "marginTop": "1rem" }} spacing={3}>
            
            <KeyboardDatePicker
                style={{"width":"60%"},{"marginLeft":"0.5rem"}}
                disableToolbar
                variant="inline"
                label="Fecha fin"
                autoOk
                format="dd/MM/yyyy"
                value={props.endDate}
                disable={false}
                onChange={date => handleChangeDatePickerPayment(date, "end")}
                disableFuture 
            />
          </Stack>
          <Modal.Footer>
            <Button
              type="submit"
              color="red"
              variant="contained"
              onClick={closePeriod}>
              Cerrar periodo
            </Button>
          </Modal.Footer>
        </Modal>
      </MuiPickersUtilsProvider>
      
    </React.Fragment>
  ) : (
    <div>Acceso denegado</div>
  );

    

};

export default ClosedPeriods;
