var url = "https://mobilia-api.complemento360.net"//api/login
//var url = "http://localhost:8001"

module.exports.LOGIN = url + `/api/login`;
module.exports.SIGNUP = url + `/api/signup/`;
module.exports.GET_USUARIO_ALL = url + `/api/get/usuario/all/`;
module.exports.GET_USUARIO_ID = url + `/api/get/usuario/id/`;
module.exports.GET_10_USUARIO_ID = url + `/api/get/usuario/ten/`;
module.exports.GET_USUARIO_USERNAME = url + `/api/get/usuario/username/`;
module.exports.GET_CLIENTE_DOCUMENTO = url + `/api/get/cliente/documento/`;
module.exports.GET_CLIENTE_DOCUMENTO_SIMILAR = url + `/api/get/cliente/documento/similar/`;
module.exports.GET_CLIENTE_NOMBRE = url + `/api/get/cliente/nombre/`;
module.exports.GET_CREDITO_ALL = url + `/api/get/credito/all/`;
module.exports.GET_CREDITO_ID = url + `/api/get/credito/id/`;
module.exports.GET_CREDITO_CLIENTE_ID = url + `/api/get/credito/cliente/`;
module.exports.GET_CREDITO_FINANCIAL_CODE = url + `/api/get/credito/financialCode/`;
module.exports.GET_FILE_ID = url + `/api/download-credit-file/fileId/`;
module.exports.DELETE_FILE_ID = url + `/api/delete-credit-file/fileId/`;
module.exports.UPLOAD_FILE_ID = url + `/api/upload-credit-file/creditId/`;
module.exports.GET_AUXILIAR_ID = url + `/api/get/auxiliar/id/0`;
module.exports.GET_UVRLOAD_ID = url + `/api/get/uvrLoad/id/`;
module.exports.GET_UVRLOAD_ALL = url + `/api/get/uvrLoad/all/`;
module.exports.GET_UVR_ALL = url + `/api/get/uvr/all/`;
module.exports.CREATE_CLIENTE = url + `/api/create/cliente/`;
module.exports.CREATE_CREDITO = url + `/api/create/credito/`;
module.exports.CREATE_UVRLOAD = url + `/api/create/uvrLoad/`;
module.exports.CREATE_PAYMENT = url + `/api/create/payment/`;
module.exports.PATCH_USUARIO_ID = url + `/api/patch/usuario/id/`;
module.exports.PATCH_CLIENTE_DOCUMENTO = url + `/api/patch/cliente/documento/`;
module.exports.PATCH_CREDITO_ID = url + `/api/patch/credito/id/`;
module.exports.PATCH_AUXILIAR_ID = url + `/api/patch/auxiliar/id/0`;
module.exports.DELETE_USUARIO_ID = url + `/api/delete/usuario/id/`;
module.exports.DELETE_CLIENTE_DOCUMENTO = url + `/api/delete/cliente/documento/`;
module.exports.DELETE_CREDITO_ID = url + `/api/delete/credito/id/`;
module.exports.GET_UVR_FECHA = url + `/api/get/uvr/date/`;
module.exports.PUT_UVR = url + `/api/put/uvr/`;
module.exports.LIQUIDATE_ALL_CREDITS = url + `/api/liquidate/credits/`;
module.exports.INFORME_COMERCIAL = url + `/api/get/credito/informe-comercial`;
module.exports.CIERRE_PERIODO = url + `/api/close/credits/`;
module.exports.CIERRE_PERIODO_FECHA = url + `/api/close/credits-dates/`;

